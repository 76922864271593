import React, { useEffect, useState } from "react";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import Rightarrow from "../../assets/img/svg/right-arrow.svg";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import moment from 'moment-timezone';
import axios from 'axios';
import { apiUrl } from "../../constants";
import db from "../../firebase-config";
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";

var token_url = apiUrl.TOKEN;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
}));


function Stepper() {
    const exchangeToken = useExchangeToken();
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);
    const [productionDetail, setProductionDetail] = useState([]);
    const [materialDescription, setMaterialDescription] = useState([]);
    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);

    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);

    useEffect(() => {
        function fetchMyAPI() {
            var get_datakorat = `${apiUrl.GET_SPECIFIC_LINE_DATA}${localStorage.getItem("work_center")}/${timestr}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
            axios.get(get_datakorat, {
                headers: {
                    Authorization: `Bearer ${ac_token}`,
                },
            }
            )
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        //console.log("sku: " + response.data.SKU_OVERVIEW);
                        setProductionDetail(response.data.PRODUCTION_DETAIL)
                    }
                })
                .catch(error => {
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()

        const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);


    useEffect(() => {
        const subscriberStatusMachine = db
            .collection("prod_mes_ignition_machine_status")
            .where("LINE", "==", localStorage.getItem("work_center"))
            .onSnapshot((querySnapshot) => {
                const statusMachine = querySnapshot.docs.map(doc => ({
                    ...doc.data(),
                    id: doc.id,
                }));
                setMaterialDescription(statusMachine);

            });
        // Cleanup listeners
        return () => {
            subscriberStatusMachine();
        };
    }, []);




    /////////////// Styling a text in react based on multiple conditions(https://stackoverflow.com/questions/70209589/styling-a-text-in-react-based-on-multiple-conditions)
    function StepMachine1(line) {
        switch (line) {
            case 'LINE7':
                return 'Fryer1';
            case 'LINE8':
                return 'Fryer'
            case 'LINE9':
                return 'Sear'
            case 'LINE10':
                return 'Tunnel Oven'
            default:
                return ''
        }
    }

    function StepMachine2(line) {
        switch (line) {
            case 'LINE7':
                return 'Fryer2';
            case 'LINE8':
                return 'Superheat'
            case 'LINE9':
                return 'Spiral Oven'
            case 'LINE10':
                return 'Tunnel Oven'
            default:
                return ''
        }
    }

    function StepMachine3(line) {
        switch (line) {
            case 'LINE7':
                return 'IQF';
            case 'LINE8':
                return 'IQF'
            case 'LINE9':
                return 'IQF'
            case 'LINE10':
                return 'IQF'
            default:
                return ''
        }
    }

    function StepMachine4(line) {
        switch (line) {
            case 'LINE7':
                return 'Multihead';
            case 'LINE8':
                return 'Counting'
            case 'LINE9':
                return 'Multihead'
            case 'LINE10':
                return 'Multihead'
            default:
                return ''
        }
    }

    let nameFryer1 = []
    let nameFryer2 = []
    let nameMultihead = []
    let nameCheckWeight = []
    let nameXray = []
    let nameMD = []

    for (let i = 0; i < materialDescription.length; i++) {
        if ((materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'EFRB5108' && materialDescription[i].LINE == 'LINE7') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'EFRB5106' && materialDescription[i].LINE == 'LINE8') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Sear' && materialDescription[i].LINE == 'LINE9') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Tunnel Oven' && materialDescription[i].LINE == 'LINE10')) {
            nameFryer1.push(materialDescription[i].MATERIAL_DESCRIPTION)
        }
        if ((materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'EFRB5107' && materialDescription[i].LINE == 'LINE7') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Superheat1' && materialDescription[i].LINE == 'LINE8') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Spiral Oven' && materialDescription[i].LINE == 'LINE9') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Tunnel Oven' && materialDescription[i].LINE == 'LINE10')) {
            nameFryer2.push(materialDescription[i].MATERIAL_DESCRIPTION || materialDescription[i].PRODUCT_NAME)
        }
        if ((materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Multihead1' && materialDescription[i].LINE == 'LINE7') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Multihead2' && materialDescription[i].LINE == 'LINE8') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Multihead3' && materialDescription[i].LINE == 'LINE9') || (materialDescription[i].IS_CONNECTED == true && materialDescription[i].MACHINE_NAME == 'Multihead4' && materialDescription[i].LINE == 'LINE10')) {
            nameMultihead.push(materialDescription[i].MATERIAL_DESCRIPTION)
        }
        if (materialDescription[i].MACHINE_NAME == 'CheckWeight' && materialDescription[i].LINE == localStorage.getItem("work_center")) {
            nameCheckWeight.push(materialDescription[i].MATERIAL_DESCRIPTION)
        }
        if (materialDescription[i].MACHINE_NAME == 'Xray' && materialDescription[i].LINE == localStorage.getItem("work_center")) {
            nameXray.push(materialDescription[i].MATERIAL_DESCRIPTION)
        }
        if (materialDescription[i].MACHINE_NAME == 'MetalDetector' && materialDescription[i].LINE == localStorage.getItem("work_center")) {
            nameMD.push(materialDescription[i].MATERIAL_DESCRIPTION)
        }
    }



    return (
        <Item sx={{ height: "100%" }}>
            <Box width="100%" sx={{ background: "#d4d4d4" }} style={{ position: "relative", top: "0px" }}>
                <Typography fontSize={'20px'} fontWeight="medium" color="black">
                    Layout
                </Typography>
            </Box>
            {productionDetail.length > 0 ?

                <Box pt={0} px={2} display="flex" justifyContent="space-between" alignItems="center">
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium">
                            NA
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                                    Preperation
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium" style={{ position: "relative", left: "-20px", width: "150px" }}>
                            {nameFryer1 != '' ? nameFryer1 : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                                    {StepMachine1(localStorage.getItem("work_center"))}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium" style={{ position: "relative", left: "-20px", width: "150px" }}>
                            {nameFryer2 != '' ? nameFryer2 : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}>
                                    {StepMachine2(localStorage.getItem("work_center"))}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium">
                            NA
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                                    {StepMachine3(localStorage.getItem("work_center"))}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium" >
                            {nameMultihead != '' ? nameMultihead : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                                    {StepMachine4(localStorage.getItem("work_center"))}
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium" >
                            {nameCheckWeight != '' ? nameCheckWeight : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}>
                                    Check weight
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium" >
                            {nameXray != '' ? nameXray : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="100px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }}  >
                                    X-Ray
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>
                    <img src={Rightarrow} height="14px" style={{ position: "relative", top: "10px" }} />
                    <Grid item xs={2} md={2} xl={1} >
                        <Typography fontSize={'16px'} fontWeight="medium">
                            {nameMD != '' ? nameMD : 'NA'}
                        </Typography>
                        <Card sx={{ background: "#3cbea8" }}>
                            <Box width="120px" height="40px" textAlign="center" >
                                <Typography fontSize={'15px'} color="white" variant="button" fontWeight="bold" style={{ position: "relative", top: "10px" }} >
                                    Metal Detector
                                </Typography>
                            </Box>
                        </Card>
                    </Grid>

                </Box>
                : <div>Not Running</div>}
        </Item>

    );
}

export default Stepper;

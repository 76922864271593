import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, Container, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import GridToSetting from '../../components/grid/GridToSetting'
import SettingsIMG from '../../assets/img/setting.png'
import File from '../../assets/img/file.png'
import { useSelector } from "react-redux";


export default function Setting() {
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    const group = useSelector((state) => state.auth.group);
    const user_role = useSelector((state) => state.auth.user_role);

    const setting_title = user_role == 'Superuser' || user_role == 'admin' ? [
        {
            title: 'Plant',
            detail: 'Setting master plant',
            icon: <img src={SettingsIMG} height="60vh" />,
            click: 'list-master-plant'
        },
        {
            title: 'Standard',
            detail: 'Setting standard for sku',
            icon: <img src={File} height="55vh" />,
            click: 'master-std-plant'
        }
    ] : user_role == 'staff' ? [
        
        {
            title: 'Standard',
            detail: 'Setting standard for sku',
            icon: <img src={File} height="55vh" />,
            click: 'master-std-plant'
        }
    ] : []



    return (

        <div style={{ width: "100%", height: "93.5vh" }}>
            <Helmet>
                <title>Setting | MES</title>
            </Helmet>
            <Grid container spacing={2}>
            <Container maxWidth="md">
                <Grid item xs={12} textAlign="center" style={{ paddingTop: 10 }}>
                    <Typography style={{ color: colors.neutral['black'], fontSize: 58, fontWeight: 'bold' }} >
                        Setting
                    </Typography>
                </Grid>


                <Grid item xs={12} style={{ paddingTop: 30 }}>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >

                        {setting_title.map((item) => {
                            return (
                                <GridToSetting
                                    icon={item.icon}
                                    title={item.title}
                                    detail={item.detail}
                                    click={item.click}
                                />
                            )
                        })
                        }
                    </Grid>
                </Grid>
                </Container>
            </Grid>
        </div>
    );
}







import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import { apiUrl } from "../../constants";
import {
  Box,
  Button,
  Card,
  CardContent,
  CardHeader,
  Typography,
  Divider,
  Grid,
  TextField,
  Stack,
  Snackbar,
  Alert
} from '@material-ui/core';
import { Helmet } from "react-helmet";
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";


function UserEdit(props) {
  const exchangeToken = useExchangeToken();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [open2, setOpen2] = useState(false);
  // กำหนดค่าเริ่มต้น
  const [values, setValues] = useState({
    first_name: '',
    last_name: '',
    email: '',
    phone_number: '',
    group: '',
    bu: '',
    plant_id: '',
    factory: '',
    line: '',
  });
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);


  useEffect(() => {
    const exchange = async () => {
      if (!isTokenExchanged) {
        try {
          await exchangeToken(); // แลกเปลี่ยนโทเค็น
          setIsTokenExchanged(true);
        } catch (err) {
          console.error("Error exchanging token:", err);
          setError("Failed to exchange token");
        }
      }
    };

    exchange();
  }, [isTokenExchanged, exchangeToken]);


  // will be trigerred every time user change input field set state of that value
  const handleChange = (event) => {
    setValues({
      ...values,
      [event.target.name]: event.target.value
    });
  };



  // useEffect(() => {
  //   async function fetchMyAPI() {
  //     const url_string = window.location.href
  //     var url = new URL(url_string);
  //     var id = url.searchParams.get("id");
  //     const GetUserById = async () => {
  //       var urls = `${apiUrl.USER}${id}/`
  //       const result = await axios({
  //         method: "get", //you can set what request you want to be
  //         url: urls,
  //         headers: {
  //           Authorization: `Bearer ${ac_token}`,

  //         }
  //       });
  //       return result.data;
  //     }
  //     var result = await GetUserById();
  //     if (result.id) {
  //       setValues(result)
  //     }
  //   }
  //   fetchMyAPI()
  // }, [ac_token]);

  useEffect(() => {
    if (isTokenExchanged && ac_token) {
      async function fetchMyAPI() {
        const url_string = window.location.href;
        var url = new URL(url_string);
        var id = url.searchParams.get("id");

        const GetUserById = async () => {
          var urls = `${apiUrl.USER}${id}/`;
          try {
            const result = await axios({
              method: "get",
              url: urls,
              headers: {
                Authorization: `Bearer ${ac_token}`,
                // "X-xss-protection": "1; mode=block;",
                // "X-frame-options": "SAMEORIGIN",
                // "X-content-type-options": "nosniff",
                // "Cache-Control": "no-cache, private",
                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
              }
            });
            return result.data;
          } catch (error) {
            console.log("Error fetching user:", error);
          }
        };

        var result = await GetUserById();
        if (result && result.id) {
          setValues(result);
        }
      }
      fetchMyAPI();
    }
  }, [isTokenExchanged, ac_token]);

  // will be triggered when uer click submit button and patch user detail
  const handleSubmit = async () => {
    // console.log(values)
    var url_edit = `${apiUrl.USER}${values.id}/`
    await axios.patch(url_edit, values, {
      headers: {
        Authorization: `Bearer ${ac_token}`,
        // "X-xss-protection": "1; mode=block;",
        // "X-frame-options": "SAMEORIGIN",
        // "X-content-type-options": "nosniff",
        // "Cache-Control": "no-cache, private",
        // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
        // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
      }
    })
      .then(response => {
        // console.log(response)
        if (response.status == 200) {
          setOpen(true);
          setTimeout(() => {

            navigate(`/account`, { replace: true })
              ;
          }, 2000);
        } else {
          setOpen2(true);
        }
      })
      .catch(error => {
        setOpen2(true);
        console.log(error)
      })
  }


  // back to userlist page
  const handleBack = () => {
    navigate(`/account`, { replace: true });
  }

  return (
    <>
      <Helmet>
        <title>Edit | MES </title>
      </Helmet>
      <form
        autoComplete="off"
        noValidate
        {...props}
      >
        <Stack spacing={2} sx={{ width: '100%' }}>
          <Snackbar open={open} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert severity="success" sx={{ width: '100%' }}>
              The changes have been saved successfully.
            </Alert>
          </Snackbar>
          <Snackbar open={open2} autoHideDuration={4000} anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}>
            <Alert severity="error" sx={{ width: '100%' }}>
              Failed to save changes.
            </Alert>
          </Snackbar>
        </Stack>
        <Card sx={{ ml: 2, mt: 5, mr: 2 }}>
          <Box sx={{ mb: 3, mt: 0, ml: 2 }}>
            <Typography color="textPrimary" style={{ fontSize: 56 }}>
              Profile
            </Typography>
            <Typography
              color="textSecondary"
              gutterBottom
              style={{ fontSize: 20 }}
            >
              The information can be edited
            </Typography>
          </Box>
          <Divider />
          <CardContent>
            <Grid
              container
              spacing={3}
            >
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  // helperText="Please specify the first name"
                  label="First name"
                  name="first_name"
                  onChange={handleChange}
                  required
                  value={values.first_name}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Last name"
                  name="last_name"
                  onChange={handleChange}
                  required
                  value={values.last_name}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Email Address"
                  name="email"
                  onChange={handleChange}
                  required
                  value={values.email}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Phone Number"
                  name="phone_number"
                  onChange={handleChange}
                  type="number"
                  value={values.phone_number}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Group"
                  name="group"
                  onChange={handleChange}
                  required
                  value={values.group}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Bu"
                  name="bu"
                  onChange={handleChange}
                  required
                  value={values.bu}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Plant"
                  name="plant_id"
                  onChange={handleChange}
                  required
                  value={values.plant_id}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Factory"
                  name="factory"
                  onChange={handleChange}
                  required
                  value={values.factory}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
              <Grid
                item
                md={6}
                xs={12}
              >
                <TextField
                  fullWidth
                  label="Line"
                  name="line"
                  onChange={handleChange}
                  required
                  value={values.line}
                  variant="outlined"
                  sx={{
                    "& .MuiOutlinedInput-input": {
                      color: "#000000",
                      fontFamily: 'CPF_Imm_Sook',
                      //fontWeight: "bold",
                      fontSize: 18
                    },
                  }}
                />
              </Grid>
            </Grid>
          </CardContent>
          <Divider />
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              p: 2
            }}
          >

            <Button
              style={{ background: '#575755', color: "white", fontSize: 18 }}
              variant="contained"
              onClick={handleBack}
              sx={{ mr: 3 }}
            >
              Back to account
            </Button>

            <Button
              style={{ background: '#009688', color: "white", fontSize: 18 }}
              variant="contained"
              onClick={handleSubmit}
            >
              Save details
            </Button>
          </Box>
        </Card>
      </form>
    </>
  );
}

UserEdit.propTypes = {};

export default UserEdit;

import React from 'react'
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Chart from 'react-apexcharts';
import { ColorModeContext, tokens } from "../../theme";



////// add icon annotations (https://github.com/apexcharts/apexcharts.js/issues/1674)

function ColumnChartGiveaway(props) {
    const scale = (((props.setting - props.std)/props.std) * 100) * 2
    //console.log(scale)

    

    return (
        <>
            <Box width="100%" mb={-8} ml={0} style={{ position: "relative", top: "1vw", height: '100%' }}>
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            stacked: 'true',
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        colors: [
                            function({ value, seriesIndex, w }) {
                              if (scale == 2 && (value == 'NA' ? 0 : value) >= 0 && (value == 'NA' ? 0 : value) <= 0.25) {
                                return "#80BAB0"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) > 0.25 && (value == 'NA' ? 0 : value) <= 0.50) {
                                return "#73D3C3"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) >  0.50 && (value == 'NA' ? 0 : value) <= 0.75) {
                                return "#9FE1D6"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) >  0.75 && (value == 'NA' ? 0 : value) <= 1) {
                                return "#C5EDE6"
                              }else if (scale == 2 && (value == 'NA' ? 0 : value) >  1 && (value == 'NA' ? 0 : value) <= 1.25) {
                                return "#FFEAA8"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) >  1.25 && (value == 'NA' ? 0 : value) <= 1.50) {
                                return "#FFDF77"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) >  1.50 && (value == 'NA' ? 0 : value) <= 1.75) {
                                return "#F4AD7E"
                              } else if (scale == 2 && (value == 'NA' ? 0 : value) >  1.75 ) {
                                return "#EE6666"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >= 0 && (value == 'NA' ? 0 : value) <= 0.50) {
                                return "#80BAB0"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) > 0.50 && (value == 'NA' ? 0 : value) <= 1) {
                                return "#73D3C3"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  1 && (value == 'NA' ? 0 : value) <= 1.50) {
                                return "#9FE1D6"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  1.50 && (value == 'NA' ? 0 : value) <= 2) {
                                return "#C5EDE6"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  2 && (value == 'NA' ? 0 : value) <= 2.5) {
                                return "#FFEAA8"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  2.5 && (value == 'NA' ? 0 : value) <= 3) {
                                return "#FFDF77"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  3 && (value == 'NA' ? 0 : value) <= 3.5) {
                                return "#F4AD7E"
                              } else if (scale == 4 && (value == 'NA' ? 0 : value) >  3.5 ) {
                                return "#EE6666"
                              }
                            }
                          ],
                        plotOptions: {
                            bar: {
                                borderRadius: 10,
                                dataLabels: {
                                    position: 'top', // top, center, bottom
                                },
                            }
                        },
                        dataLabels: {
                            enabled: true,
                            formatter: function (val) {
                                return val ;
                            },
                            offsetY: -24,
                            style: {
                                fontSize: props.value >= '10' ? 14 : 18,
                                fontFamily: 'CPF_Imm_Sook',
                                colors: ["#ffffff"]
                            }
                        },
                        legend: {
                            show: false,
                        },
                        tooltip: {
                            enabled: false,
                          },
                        xaxis: {
                            categories: props.time,
                            position: 'bottom',
                            labels: {
                                style: {
                                  fontFamily: 'CPF_Imm_Sook',
                                  colors: '#ffffff',
                                  fontSize: 14
                                }
                              },
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                            crosshairs: {
                                fill: {
                                    type: 'gradient',
                                    gradient: {
                                        colorFrom: '#D8E3F0',
                                        colorTo: '#BED1E6',
                                        stops: [0, 100],
                                        opacityFrom: 0.4,
                                        opacityTo: 0.5,
                                    }
                                }
                            },
                            tooltip: {
                                enabled: true,
                            }
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: false,
                                formatter: function (val) {
                                    return val + "%";
                                }
                            },
                            min : 0,
                            max : scale

                        },
                        grid: {
                            show: false,
                        },
                        annotations: {
                            yaxis: [{
                                y: props.target,
                                borderColor: '#4AC6B1',
                                strokeDashArray: 0,
                                borderWidth: 2,
                                /*label: {
                                  borderColor: '#4AC6B1',
                                  offsetY: 20,
                                  style: {
                                    color: '#fff',
                                    background: '#4AC6B1',
                                    fontFamily: 'CPF_Imm_Sook',
                                    fontWeight: 'bold',
                                    fontSize: 16
                                    
                                  },
                                  text: 'Target: 1',
                                }*/
                              }
                            ]
                        }
                    }}
                    series={
                        [{
                            name: 'actual',
                            data: props.value
                        }]
                    }
                    type='bar'
                    width='105%'
                    height='100%'
                />
            </Box>
        </>
    )
}

ColumnChartGiveaway.propTypes = {}

export default ColumnChartGiveaway

// Import the functions you need from the SDKs you need
import "firebase/auth"
import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
  apiKey: "AIzaSyD-1ncb4e-256FaHPYPa0HQOCjCiEdnCA0",
  authDomain: "enc-strategy-mes.firebaseapp.com",
  projectId: "enc-strategy-mes",
  storageBucket: "enc-strategy-mes.firebasestorage.app",
  messagingSenderId: "239655014002",
  appId: "1:239655014002:web:64f05f2cb922f7952efceb",
  measurementId: "G-H3FGB5HZT6"
};

// Initialize Firebase
const firebaseApp = firebase.initializeApp(firebaseConfig);
const db = firebaseApp.firestore();
const auth = firebase.auth();
const provider = new firebase.auth.GoogleAuthProvider();


export { auth, provider, firebase };
export default db;
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Card, Container, Typography } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import MarinationTrackingPage1 from "../pages/MarinationTrackingPage1"
import MarinationTrackingPage2 from "../pages/MarinationTrackingPage2"



function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function MarinationTab() {
    const [value, setValue] = React.useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const StyledTab = styled(Tab)({
        "&.Mui-selected": {
            color: "red"
        }
    });


    return (

        <div className="App">
            <Helmet>
                <title>Marination Tracking | MES</title>
            </Helmet>
                <Grid
                    container
                    style={{ paddingTop: 0, backgroundColor: '#000000' }}
                >
                    <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                        <Box sx={{ width: '100%' }}>
                            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                <Tabs sx={{
                                    "& .MuiTab-root.Mui-selected": { color: "#88dbb0" },
                                    "& .MuiTabs-indicator": { bgcolor: "#88dbb0" }
                                }} value={value} onChange={handleChange} aria-label="basic tabs example" >
                                    <Tab  style={{color: '#ffffff'}} label="Marination Page1" {...a11yProps(0)} />
                                    <Tab  style={{color: '#ffffff'}} label="Marination Page2" {...a11yProps(1)} />
                                </Tabs>
                            </Box>
                            <CustomTabPanel value={value} index={0} >
                                <MarinationTrackingPage1 />
                            </CustomTabPanel>

                            <CustomTabPanel value={value} index={1}>
                                <MarinationTrackingPage2 />
                            </CustomTabPanel>

                        </Box>
                    </Grid>
                </Grid>
        </div>
    );
}






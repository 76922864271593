import { useRoutes, Navigate } from 'react-router-dom';
import BUList from "./pages/BUList";
import PlantList from './pages/PlantList';
import FactoryList from "./pages/FactoryList"
import LineList from './pages/LineList';
import LineDetail from './pages/LineDetail';
import ExternalRedirect from "./components/auth/ExternalRedirect";
import MainLayout from './components/layout/MainLayout';
import PerformanceTab from './pages/PerformanceTab';
import GiveawayTab from './pages/GiveawayTab'
import MarinationTab from './pages/MarinationTab';
import InfeedTab from './pages/InfeedTab';
import AllUser from './pages/account_page/AllUser';
import Register from './pages/account_page/Register';
import UserEdit from './components/account/UserEdit';
import Setting from './pages/setting_page/Setting';
import MasterPlantEdit from './components/setting/MasterPlantEdit';
import NewMasterPlant from './pages/setting_page/NewMasterPlant';
import MasterPlantList from './pages/setting_page/MasterPlant';
import MasterSTDPlant from './pages/setting_page/MasterSTDPlant';
import MasterSTDFactory from './pages/setting_page/MasterSTDFactory'
import MasterSTDLisi from './pages/setting_page/MasterSTD'
import NewMasterSTD from './pages/setting_page/NewMasterSTD';
import StandardSettingEdit from './components/setting/StandardSettingEdit';
import CorrelationTab from './pages/CorrelationTab';
import OverviewTab from "./pages/mes_korat_on_web/OverviewTab";
import ProductionLine from './pages/mes_korat_on_web/ProductionLine';




const routes = (isLoggedIn) => [
  // Need auth pages
  {
    path: "/",
    element: isLoggedIn ? <MainLayout /> : <ExternalRedirect to="https://cpf.axonscloud.com/login" />,
    children: [
      { path: "/", element: <BUList /> },
      { path: ":bu/", element: <PlantList /> },
      { path: ":bu/:plant", element: <FactoryList /> },
      { path: ":bu/:plant/:factory", element: <LineList /> },
      { path: ":bu/:plant/:factory/:line", element: <LineDetail /> },
      {
        path: ":bu/:plant/:factory/:line/:detail", element: (localStorage.getItem("factoryName") === "Performance" ? <PerformanceTab /> 
        : localStorage.getItem("factoryName") === "Giveaway" ||  localStorage.getItem("factoryName") === "Yield Loss" ? <GiveawayTab /> 
        : localStorage.getItem("factoryName") === "Marination Tracking" ? <MarinationTab /> 
        : localStorage.getItem("factoryName") === "MobileInfeed" || localStorage.getItem("factoryName") === "Infeed" ? <InfeedTab /> 
        : localStorage.getItem("factoryName") === "Unlockgiveaway" || localStorage.getItem("factoryName") === "Correlation" ? <CorrelationTab /> 
        : localStorage.getItem("factoryName") === "OverviewDashboard" || localStorage.getItem("factoryName") === "overview" ?<OverviewTab />
        : <BUList />)
      },
      { path: "account", element: <AllUser /> },
      { path: "register", element: <Register /> },
      { path: "user-edit", element: <UserEdit /> },
      { path: "setting", element: <Setting /> },
      { path: "new-master-plant", element: <NewMasterPlant /> },
      { path: "edit-master-plant", element: <MasterPlantEdit /> },
      { path: "list-master-plant", element: <MasterPlantList /> },
      { path: "master-std-plant", element: <MasterSTDPlant /> },
      { path: "master-std-factory", element: <MasterSTDFactory /> },
      { path: "list-master-std", element: <MasterSTDLisi /> },
      { path: "new-master-std", element: <NewMasterSTD /> },
      { path: "edit-master-std", element: <StandardSettingEdit /> },
      { path: "productionLine", element: <ProductionLine /> },
      { path: "/", element: <Navigate to="/home" /> },

    ],
  },
];

function RoutesConfig({ loggedIn }) {
  console.log('in Route config: ', loggedIn)
  const routing = useRoutes(routes(loggedIn));
  return routing;
}

export default RoutesConfig;
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { useNavigate } from 'react-router-dom';
import { Box, Grid, Card, Container, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import GridToSettingPlant from '../../components/grid/GridToSettingPlant'
import Factory from '../../assets/img/factory.png'
import MasterFooter from "../../components/setting/MasterFooter"


const setting_title = [
    {
        title: '4117',
        detail: 'Setting master std in plant 4117',
        icon: <img src={Factory} height="60vh" />,
        click: 'master-std-factory'
    }
]



export default function MasterSTDPlant() {
    const navigate = useNavigate();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);

    function storeCourse(name) {
        sessionStorage.setItem('master_std_plant', name)
      }


    return (

        <div style={{ width: "100%", height: "93.5vh" }}>
            <Helmet>
                <title>STD Plant | MES</title>
            </Helmet>
            <Grid container spacing={2}>
                <Container maxWidth="md">
                    <Grid item xs={12} textAlign="center" style={{ paddingTop: 10 }}>
                        <Typography style={{ color: colors.neutral['black'], fontSize: 58, fontWeight: 'bold' }} >
                            Plant
                        </Typography>
                    </Grid>


                    <Grid item xs={10} style={{ paddingTop: 30 }}>
                        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }} >
                            {setting_title.map((item) => {
                                return (
                                    <Grid item xs={12} onClick={() => { navigate(`/${item.click}`, { replace: true }); storeCourse(item.title) }}>
                                        <GridToSettingPlant 
                                        icon={item.icon}
                                        title={item.title}
                                        detail={item.detail}
                                        />
                                    </Grid>
                                )
                            })
                            }
                        </Grid>
                    </Grid>
                </Container>
            </Grid>
            <Grid item xs={12} style={{paddingTop: 465, paddingLeft: 10}}>
                <MasterFooter
                    click={'setting'}
                />
            </Grid>
        </div>
    );
}







import React, { useEffect, useState } from "react";
import { apiUrl } from "../../constants/index";
import Table from '@mui/material/Table';
import { Box, Card, Grid, TableBody, TableCell, TableContainer, TableRow } from "@mui/material";
import Typography from "@mui/material/Typography";
import { Helmet } from "react-helmet";
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { DataGrid } from "@mui/x-data-grid";
import axios from 'axios';
import db from "../../firebase-config";
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";
import { useSelector } from 'react-redux';


var token_url = apiUrl.TOKEN;

const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
    ...theme.typography.body2,
    padding: theme.spacing(1),

    color: theme.palette.text.secondary,
}));


const columns = [
    { id: 'PLANT', label: 'PLANT', minWidth: 170 },
    { id: 'LINE', label: 'LINE', minWidth: 100 },
    { id: 'MATERIAL_DESCRIPTION', label: 'MATERIAL_DESCRIPTION', minWidth: 100 },
    { id: 'MATERIAL_CODE', label: 'MATERIAL_CODE', minWidth: 100 },
    { id: 'TOTAL_WEIGHT', label: 'TOTAL_WEIGHT', minWidth: 100 },
    { id: 'UNIT', label: 'UNIT', minWidth: 100 },
    { id: 'START_DATETIME', label: 'START_DATETIME', minWidth: 100 },
    { id: 'FINISHED_DATETIME', label: 'FINISHED_DATETIME', minWidth: 100 },

];

function Monitor() {
    const exchangeToken = useExchangeToken();
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);
    const [posts, setPosts] = useState([]);
    const [mutilhead, setMutilhead] = useState([]);
    const [IQF, setIQF] = useState([]);
    const [hotair, setHotair] = useState([]);
    const [fryer, setFryer] = useState([]);
    const [tumbler, setTumbler] = useState([]);
    const [data, setData] = useState([]);

    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);

    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(+event.target.value);
        setPage(0);
    };

    /// map my firestore collection documents into a table(https://stackoverflow.com/questions/72763199/how-to-map-my-firestore-collection-documents-into-a-table)
    useEffect(() => {
        const getPostsFromFirebase = [];
        const subscriber = db
            .collection("test_history_meskr_mqtt_total_weight_line")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('id: ' + doc.id);
                    getPostsFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                        PLANT: doc.data().PLANT,
                        LINE: doc.data().LINE,
                        MATERIAL_DESCRIPTION: doc.data().MATERIAL_DESCRIPTION,
                        MATERIAL_CODE: doc.data().MATERIAL_CODE,
                        TOTAL_WEIGHT: doc.data().TOTAL_WEIGHT,
                        UNIT: doc.data().TOTAL_WEIGHT_UNIT,
                        START_DATETIME: doc.data().START_DATETIME,
                        FINISHED_DATETIME: doc.data().FINISHED_DATETIME,
                    });
                });
                setPosts(getPostsFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [posts]); // empty dependencies array => useEffect only called once


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_machine_status")
            .where("MACHINE_TYPE", "==", "Multihead")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('idmutihead: ' + doc.id);
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setMutilhead(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [mutilhead]);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_machine_status")
            .where("MACHINE_TYPE", "==", "IQF")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('idmutihead: ' + doc.id);
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setIQF(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [IQF]);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_machine_status")
            .where("MACHINE_TYPE", "==", "HotAir")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('idmutihead: ' + doc.id);
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setHotair(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [hotair]);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_machine_status")
            .where("MACHINE_TYPE", "==", "Fryer")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('idmutihead: ' + doc.id);
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setFryer(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [fryer]);

    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_machine_status")
            .where("MACHINE_TYPE", "==", "Tumbler")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    //console.log('idmutihead: ' + doc.id);
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setTumbler(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [tumbler]);

    useEffect(() => {
        async function fetchMyAPI() {
            var get_datakorat = `${apiUrl.GET_SAP_DATA}` // date format must be YYYY-MM-DD, eg. 2022-06-16
            await axios.get(get_datakorat, {
                headers: {
                    Authorization: `Bearer ${ac_token}`,
                },
            }
            )
                .then(response => {
                    // console.log(response)
                    if (response.status == 200) {
                        //console.log(response.data.PRODUCTION_DETAIL);
                        setData(response.data.PRODUCTION_DETAIL)

                    }
                })
                .catch(error => {
                    //console.log('------------------->')
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()

        const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);


    return (
        <>
            <Helmet>
                <title>Monitor | MES Korat Fur2</title>
            </Helmet>
            <Grid container spacing={2} style={{paddingLeft: 10}}>
                {/* Top row */}
                <Grid id="top-row" container sx={{ pt: 1.5 }} spacing={2}>
                    <Grid xs={12}>
                        <Item sx={() => ({
                            textAlign: 'center',
                            width: '100%',
                            height: '115%',
                        })}>
                            {/* <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center">
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    History
                                    <TableContainer sx={{ maxHeight: 440, width: 1400 }}>
                                        <Table stickyHeader aria-label="sticky table">
                                            <TableHead>
                                                <TableRow>
                                                    {columns.map((column) => (
                                                        <TableCell
                                                            key={column.id}
                                                            align={column.align}
                                                            style={{ minWidth: column.minWidth, fontSize: 14, fontWeight: "bold", background: "#ffffff"}}
                                                        >
                                                            {column.label}
                                                        </TableCell>
                                                    ))}
                                                </TableRow>
                                            </TableHead>
                                            <TableBody>
                                                {posts.length > 0 ? posts
                                                    .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                                    .map((row) => {
                                                        return (
                                                            <TableRow hover role="checkbox" tabIndex={-1} key={row.code}>
                                                                {columns.map((column) => {
                                                                    const value = row[column.id];
                                                                    return (
                                                                        <TableCell key={column.id} align={column.align} style={{ fontSize: 16, color: "black" }}>
                                                                            {column.format && typeof value === 'number'
                                                                                ? column.format(value)
                                                                                : value}
                                                                        </TableCell>
                                                                    );
                                                                })}
                                                            </TableRow>
                                                        );
                                                    }) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                    <TablePagination
                                        rowsPerPageOptions={[10, 25, 100]}
                                        component="div"
                                        count={posts.length}
                                        rowsPerPage={rowsPerPage}
                                        page={page}
                                        onPageChange={handleChangePage}
                                        onRowsPerPageChange={handleChangeRowsPerPage}
                                    />
                                </Typography>
                            </Box> */}

                            <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "30px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    Multihead
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>LINE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>NAME</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>MATERIAL_CODE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>DESCRIPTION</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>STATUS</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TOTAL_WEIGHT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>UNIT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TIMESTAMP</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>START</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>FINISHED</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>MACHINE_ERROR_CODE</TableCell>

                                            </TableRow>
                                            <TableBody>
                                                {mutilhead.length > 0 ? mutilhead.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.LINE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_NAME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_CODE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_STATUS}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.TOTAL_WEIGHT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.TOTAL_WEIGHT_UNIT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.TIMESTAMP}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.START_DATETIME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.FINISHED_DATETIME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_ERROR_CODE_DESCRIPTION}</TableCell>
                                                    </TableRow>
                                                )) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box>

                            <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "70px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    IQF
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>LINE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>NAME</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>BELT_RUNNING</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>FAULT_SUMMARY</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TIMESTAMP</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {IQF.length > 0 ? IQF.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.LINE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_NAME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{(item.BELT_RUNNING).toString()}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{(item.FAULT_SUMMARY).toString()}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.T}</TableCell>
                                                    </TableRow>
                                                )) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box>

                            <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "120px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    HotAir
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>LINE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>NAME</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>DESCRIPTION</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>STATUS</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TIMESTAMP</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {hotair.length > 0 ? hotair.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.LINE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_NAME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_STATE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.T}</TableCell>
                                                    </TableRow>
                                                )) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box>

                            <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "170px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    Fryer
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>LINE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>NAME</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>DESCRIPTION</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>STATUS</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TIMESTAMP</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {fryer.length > 0 ? fryer.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.LINE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_NAME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_STATE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.T}</TableCell>
                                                    </TableRow>
                                                )) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box>

                            <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "220px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    Tumbler
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>LINE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>NAME</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>DESCRIPTION</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>STATUS</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TIMESTAMP</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {tumbler.length > 0 ? tumbler.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.LINE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_NAME}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MACHINE_STATE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.T}</TableCell>
                                                    </TableRow>
                                                )) : console.log()}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box>


                            {/* <Box pt={1} px={0} display="flex" justifyContent="space-between" alignItems="center" style={{ position: "relative", top: "270px" }}>
                                <Typography color="black" fontSize={'22px'} fontWeight="bold">
                                    PO Data
                                    <TableContainer component={Paper} >
                                        <Table sx={{ width: 1400 }} aria-label="simple table" >
                                            <TableRow>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>PLANT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>WORK_CENTER</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>MATERIAL_DESCRIPTION</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>MATERIAL_CODE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>TARGET</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>UNIT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>SCHEDULE_START_DATE</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>SHIFT</TableCell>
                                                <TableCell align="center" style={{ fontSize: 16, fontWeight: "bold", color: "black" }}>SHIFT_PERIOD</TableCell>
                                            </TableRow>
                                            <TableBody>
                                                {data.map((item, index) => (
                                                    <TableRow
                                                        key={index}
                                                        sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                    >
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PLANT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.WORK_CENTER}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_DESCRIPTION}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.MATERIAL_CODE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.TARGET}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.PRODUCTION_UNIT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.SCHEDULE_START_DATE}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.SHIFT}</TableCell>
                                                        <TableCell align="center" style={{ fontSize: 16, color: "black" }}>{item.SHIFT_PERIOD}</TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Typography>
                            </Box> */}

                        </Item>
                    </Grid>
                </Grid>



            </Grid>

        </>
    );
}

export default Monitor;
import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { apiUrl } from '../../constants'
import axios from 'axios'
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import MasterPlantList from "../../components/setting/MasterPlantList";
import MasterPlantToolbar from "../../components/setting/MasterPlantToolbar";
import MasterFooter from "../../components/setting/MasterFooter"
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";



const MasterPlant = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [allPlant, setAllPlant] = useState([])
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);



  useEffect(() => {
    const exchange = async () => {
      if (!isTokenExchanged) {
        try {
          await exchangeToken(); // แลกเปลี่ยนโทเค็น
          setIsTokenExchanged(true);
        } catch (err) {
          console.error("Error exchanging token:", err);
          setError("Failed to exchange token");
        }
      }
    };

    exchange();
  }, [isTokenExchanged, exchangeToken]);



  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.MASTER_PLANT_STD}`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
            // "X-xss-protection": "1; mode=block;",
            // "X-frame-options": "SAMEORIGIN",
            // "X-content-type-options": "nosniff",
            // "Cache-Control": "no-cache, private",
            // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
            // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
          },
        });

        if (response.status === 200) {
          setAllPlant(response.data || [])

        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching module data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);


  return (
    <Box style={{ width: "100%", height: "93.5vh" }}>
      <Helmet>
        <title>Master Plant | MES</title>
      </Helmet>
      <Grid container spacing={2} style={{ paddingTop: 10, paddingLeft: 10, paddingRight: 10 }}>
        <Grid item xs={12}>
          <MasterPlantToolbar />
        </Grid>
        <Grid item xs={12} textAlign='center'>
          <MasterPlantList customers={allPlant} />
        </Grid>
        <Grid item xs={12} style={{ paddingTop: 10 }}>
          <MasterFooter
            click={'setting'}
          />
        </Grid>

      </Grid>
    </Box>
  )
}

export default MasterPlant

import React, { useEffect, useState, useMemo } from "react";
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import CardPhoto from '../components/card/CardPhotoToUseLocation';
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import chickenKoratImg from "../assets/img/chickenKorat.jpg"
import { useSelector } from 'react-redux';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";


const CardPhotoMemo = React.memo(CardPhoto);

const LineList = () => {
  const exchangeToken = useExchangeToken();
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [line, setLine] = useState([]);
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);
  const line_store = useSelector((state) => state.auth.line);

  function storeCourse(name) {
    localStorage.setItem('line', name)
  }

  const factoryList = [
    { id: 1, name: "Line 7", fileLocation: chickenKoratImg },
    { id: 2, name: "Line 8", fileLocation: chickenKoratImg },
    // {id: 3, name: "Line 9", fileLocation: chickenKoratImg},
  ]

  useEffect(() => {
    const fetchInitialTokenAndData = async () => {
      try {
        const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
        if (token) {
          setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
        }
      } catch (err) {
        console.error("Error exchanging token:", err);
        setError("Failed to exchange token");
      }
    };

    fetchInitialTokenAndData();
  }, [exchangeToken]);


  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.MASTER_PLANT}${localStorage.getItem("buName")}/${localStorage.getItem("plant")}/${localStorage.getItem("factory")}`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
            // "X-xss-protection": "1; mode=block;",
            // "X-frame-options": "SAMEORIGIN",
            // "X-content-type-options": "nosniff",
            // "Cache-Control": "no-cache, private",
            // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
            // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
          },
        });

        if (response.status === 200) {
          setLine(response.data.PLANT || [])
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching Line data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);

  line.sort((a, b) => {
    if (a.length !== b.length) {
      return a.length - b.length;
    }
    return a.localeCompare(b);
  });

  const filterBUData = (line_store, line_from_api) => {
    if (line_store === "All") {
      return line_from_api; // แสดงผลทั้งหมดถ้า bu_store เป็น 'all'
    } else if (line_store === "None") {
      return line_from_api;
    } else if (line_store === "-") {
      return line_from_api;
    }
    return line_from_api.filter((item) => item === line_store); // กรองเฉพาะ BU ที่ตรงกับ bu_store
  };

  // การใช้ useMemo เพื่อลดการคำนวณซ้ำเมื่อข้อมูล BU หรือ bu_store เปลี่ยน
  const lineData = useMemo(() => {
    const filteredBu = filterBUData(line_store, line); // ใช้ฟังก์ชันกรอง
    return filteredBu.length === 0
      ? []
      : filteredBu.map((name, index) => ({ key: index, name, fileLocation: chickenKoratImg }));
  }, [line, line_store]);




  return (
    <Box style={{ width: "100%", height: "90vh" }}>
      <Helmet>
        <title>Line List | MES</title>
      </Helmet>
      <Grid container spacing={2}>
        <Grid item xs={12} sm={12}>
          <Box
            m='1%'
            display="grid"
            gridTemplateColumns="repeat(12, 1fr)"
            gridAutoRows="100%"
            gap="1%"
          >
            {/* ESTIMATED */}
            {lineData.map((item) => (
              <Box
                key={item.key || item.id}
                gridColumn="span 2"
                display="flex"
                alignItems="center"
                justifyContent="center"
                onClick={() => storeCourse(item.name)}
              >
                <CardPhotoMemo name={item.name} fileLocation={item.fileLocation || chickenKoratImg} />
              </Box>
            ))
            }
          </Box>
        </Grid>
      </Grid>
    </Box>
  )
}

export default LineList

import { useRef, useEffect, useState } from 'react'
import { useParams, useLocation, useSearchParams } from 'react-router-dom'
import { Helmet } from "react-helmet";
import { apiUrl } from '../constants'
import axios from 'axios'
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../theme";
import HeaderDetail from '../components/header/HeaderDetail';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import LightModeOutlinedIcon from "@mui/icons-material/LightModeOutlined";
import DarkModeOutlinedIcon from "@mui/icons-material/DarkModeOutlined";
import GaugeChart from "../components/chart/GaugeChart"
import db from "../firebase-config";
import { useSelector } from 'react-redux';
import { useDispatch } from 'react-redux';
import { setState } from '../redux/authSlice';
import useCheckToken from '../hooks/useCheckToken';
import useExchangeToken from "../hooks/useExchangeToken";




const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: '#bcd6d0',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: '0.8px dashed #2e9281',
    borderRadius: 30,
    height: '100%'

}));

const ItemGiveaway = styled(Paper)(({ theme }) => ({
    backgroundColor: '#000000',
    padding: theme.spacing(0),
    textAlign: 'center',
    border: "3px dashed #4a4a49",
    borderRadius: 30,
    //height: '102%'

}));

const ItemActual = styled(Paper)(({ theme }) => ({
    backgroundColor: '#4a4a49',
    padding: theme.spacing(0),
    textAlign: 'center',
    //border: "3px dashed #4a4a49",
    borderRadius: 20,
    //height: '102%'

}));


const GiveawayDetailPage1 = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);

    const [no_data, setNo_data] = useState(false);
    const [giveawayP1, setGiveawayP1] = useState({
        WARNING: "",
        LINE: "",
        SHIFT: "",
        SKU: "",
        TARGET_KG: "",
        ACTUAL_KG: "",
        TARGET_PACK: "",
        ACTUAL_PACK: "",
        REMAINING_PACK: "",
        REJECT_PACK: "",
        PACK_SIZE_STD: "",
        SETTING_PACK_SIZE: "",
        AVG_PACK_SIZE: "",
        ACTUAL_PERCENT_GIVEAWAY: "",
        COST_SAVING_PACK: "",
        TARGET_GIVEAWAY: "",
        MIN_GIVEAWAY: ""
    });
    const [keeplive, setkeeplive] = useState([]);
    const [error, setError] = useState(null);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const fetchInitialTokenAndData = async () => {
            try {
                const token = await exchangeToken(); // ตรวจสอบหรือแลกเปลี่ยนโทเค็น
                if (token) {
                    setIsTokenExchanged(true); // ระบุว่าโทเค็นพร้อม
                }
            } catch (err) {
                console.error("Error exchanging token:", err);
                setError("Failed to exchange token");
            }
        };

        fetchInitialTokenAndData();
    }, [exchangeToken]);

    const api_giveaway = () => {
        try {
          // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
          const buName = localStorage.getItem("buName");
          const plant = localStorage.getItem("plant");
          const factory = localStorage.getItem("factory");
          const line = localStorage.getItem("line");
    
          // ตรวจสอบเงื่อนไขโดยใช้ object mapping
          const apiMapping = {
            "แปรรูปไก่_4117_FPP2_Line7": apiUrl.GIVEAWAY_PAGE1,
            "แปรรูปไก่_4117_FPP2_Line8": apiUrl.GIVEAWAY_LINE8_PAGE1,
            "แปรรูปไก่_4117_FPP2_Line9": apiUrl.GIVEAWAY_LINE9_PAGE1,
            "แปรรูปไก่_4117_FPP2_Line10": apiUrl.GIVEAWAY_LINE10_PAGE1,
          };
    
          // สร้าง key สำหรับจับคู่
          const key = `${buName}_${plant}_${factory}_${line}`;
    
          // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
          return apiMapping[key];
        } catch (error) {
          console.error("Error in api_performance:", error);
          return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
      };
    
      const line_giveaway = () => {
        try {
          // ดึงค่าจาก localStorage มาเก็บไว้ในตัวแปร
          const buName = localStorage.getItem("buName");
          const plant = localStorage.getItem("plant");
          const factory = localStorage.getItem("factory");
          const line = localStorage.getItem("line");
    
          // ตรวจสอบเงื่อนไขโดยใช้ object mapping
          const lineMapping = {
            "แปรรูปไก่_4117_FPP2_Line7": 'Line7',
            "แปรรูปไก่_4117_FPP2_Line8": 'Line8',
            "แปรรูปไก่_4117_FPP2_Line9": 'Line9',
            "แปรรูปไก่_4117_FPP2_Line10": 'Line10',
          };
    
          // สร้าง key สำหรับจับคู่
          const key = `${buName}_${plant}_${factory}_${line}`;
    
          // คืนค่า API URL หรือ undefined หากไม่มี key ตรงกัน
          return lineMapping[key];
        } catch (error) {
          console.error("Error in line_performance:", error);
          return null; // คืนค่า null หากเกิดข้อผิดพลาด
        }
      };
   

    useEffect(() => {
        async function fetchMyAPI() {
            await axios.get(`${api_giveaway()}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                }
            }
            )
                .then(response => {
                    //console.log(response.data)
                    if (response.status == 200) {
                        setGiveawayP1({
                            WARNING: response.data[0].WARNING,
                            LINE: response.data[0].LINE,
                            SHIFT: response.data[0].SHIFT,
                            SKU: response.data[0].SKU,
                            TARGET_KG: response.data[0].TARGET_KG,
                            ACTUAL_KG: response.data[0].ACTUAL_KG,
                            TARGET_PACK: response.data[0].TARGET_PACK,
                            ACTUAL_PACK: response.data[0].ACTUAL_PACK,
                            REMAINING_PACK: response.data[0].REMAINING_PACK,
                            REJECT_PACK: response.data[0].REJECT_PACK,
                            PACK_SIZE_STD: response.data[0].PACK_SIZE_STD,
                            SETTING_PACK_SIZE: response.data[0].SETTING_PACK_SIZE,
                            AVG_PACK_SIZE: response.data[0].AVG_PACK_SIZE,
                            ACTUAL_PERCENT_GIVEAWAY: response.data[0].ACTUAL_PERCENT_GIVEAWAY,
                            COST_SAVING_PACK: response.data[0].COST_SAVING_PACK,
                            TARGET_GIVEAWAY: response.data[0].TARGET_GIVEAWAY,
                            MIN_GIVEAWAY: response.data[0].MIN_GIVEAWAY,
                        })
                        setNo_data(1)

                    }
                })
                .catch(error => {
                    setNo_data(2)
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        const interval = setInterval(() => fetchMyAPI(), 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);


    useEffect(() => {
        const getPostFromFirebase = [];
        const subscriber = db
            .collection("prod_mes_ignition_keep_alive")
            .onSnapshot((querySnapshot) => {
                querySnapshot.forEach((doc) => {
                    getPostFromFirebase.push({
                        ...doc.data(),
                        id: doc.id,
                    });
                });
                setkeeplive(getPostFromFirebase);

            });

        // return cleanup function
        return () => subscriber();
    }, [keeplive]);


    const multihead1 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead1.Line7_1.0NoError"));
    const multihead2 = keeplive.find((user) => user.ID.includes("Further.4117.Multihead.Multihead2.Line7_2.0NoError"));
    const check_weihgt = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.CW.Line7.Status"));
    const xray = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.X_RAY.Line7.Status"));
    const md = keeplive.find((user) => user.ID.includes("Further.4117.IFORT.MD_B.Line7.Status"));

    const scale = (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : ((giveawayP1.SETTING_PACK_SIZE - giveawayP1.PACK_SIZE_STD) / giveawayP1.PACK_SIZE_STD) * 100) * 2

    const getColorGiveaway = () => {
        let color;
        try {
            if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.25) {
                color = '#2c8c7c'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.50) {
                color = '#37af9b'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.75) {
                color = '#56cab7'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.75 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#96ded2'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.25) {
                color = '#f5c842'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.25 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.50) {
                color = '#fcad00'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.50 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.75) {
                color = '#ec7728'
            } else if (scale == 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.75) {
                color = '#e20000'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) >= 0 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 0.5) {
                color = '#2c8c7c'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 0.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1) {
                color = '#37af9b'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 1.5) {
                color = '#56cab7'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 1.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2) {
                color = '#96ded2'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 2.5) {
                color = '#f5c842'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 2.5 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3) {
                color = '#fcad00'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) <= 3.5) {
                color = '#ec7728'
            } else if (scale == 4 && (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : giveawayP1.ACTUAL_PERCENT_GIVEAWAY)) > 3.5) {
                color = '#e20000'
            }
            return color;
        }
        catch {
            console.log()
        }
    }

    return (
        <>
            <Helmet>
                <title>Giveaway Page1 | MES</title>
            </Helmet>
            <Grid container spacing={2}>
                <Grid item xs={12} sm={12}>
                    <HeaderDetail
                        // line={no_data == 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'Line7' : giveawayP1.LINE) : 'Line7'}
                        line={line_giveaway()}
                        sku={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 'ไม่ตรงแผนผลิต' : giveawayP1.SKU) : '-'}
                        icon={giveawayP1.SHIFT === "N" ? (<DarkModeOutlinedIcon style={{ height: '55px' }} />) : (<LightModeOutlinedIcon style={{ height: '55px' }} />)}
                    />
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.neutral['black'], paddingTop: 10, paddingRight: 10, paddingLeft: 10, position: "relative", left: '-1.5vw' }}>
                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                    <Grid item xs={5} style={{ paddingRight: 10 }}>
                        <Item>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                    FG (kg)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={5.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                                Target
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(giveawayP1.TARGET_KG)).toLocaleString()) : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.2} style={{ borderLeft: "2px dashed #000000", height: '12vh', position: "relative", top: '0.5vw' }} >

                                    </Grid>

                                    <Grid item xs={5.9}>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                                Actual
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : Math.round(Number(giveawayP1.ACTUAL_KG)).toLocaleString()) : '-'}
                                            </Typography>
                                        </Grid>
                                        {/* <Box style={{height: '27px'}} >
                                               
                                        </Box> */}
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    <Grid item xs={7}>
                        <Item>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                    FG (bag)
                                </Typography>
                            </Grid>
                            <Grid item xs={12} textAlign="center" style={{ paddingTop: 0 }}>
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3.9} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                                Target
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_PACK == '-' ? '-' : Math.round(Number(giveawayP1.TARGET_PACK)).toLocaleString())) : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "2px dashed #000000", height: '12vh', position: "relative", top: '0.5vw' }} >

                                    </Grid>

                                    <Grid item xs={3.9}>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['black'], fontSize: 42, fontWeight: 'bold' }} >
                                                Actual
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['green'], fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PACK == '-' ? '-' : Math.round(Number(giveawayP1.ACTUAL_PACK)).toLocaleString())) : '-'}
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                <Typography style={{ color: "#636363", fontSize: 28, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                    Diff:
                                                </Typography>
                                                <Typography style={{ color: "#636363", fontSize: 32, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REMAINING_PACK == '-' ? '-' : Math.round(Number(giveawayP1.REMAINING_PACK)).toLocaleString())) : '-'}
                                                </Typography>
                                            </Box>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={0.1} style={{ borderLeft: "2px dashed #000000", height: '12vh', position: "relative", top: '0.5vw' }} >

                                    </Grid>

                                    <Grid item xs={3.9}>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: '#8c0e0e', fontSize: 42, fontWeight: 'bold' }} >
                                                Reject
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: '#8c0e0e', fontSize: 54, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.REJECT_PACK == '-' ? '-' : Math.round(Number(giveawayP1.REJECT_PACK)).toLocaleString())) : '-'}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                </Grid>
            </Grid>

            <Grid item xs={12} sm={12} style={{ backgroundColor: colors.neutral['black'], paddingTop: 10, paddingRight: 10, paddingLeft: 10, position: "relative", left: '-1.5vw' }}>
                <ItemGiveaway>
                    <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                        <Grid item xs={3.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold' }} >
                                        Pack Size
                                    </Typography>
                                    <Typography style={{ color: colors.neutral['white'], fontSize: 26, fontWeight: 'bold', paddingTop: 10 }} >
                                        &nbsp;(g/bag)
                                    </Typography>
                                </Box>

                                <Typography style={{ color: colors.neutral['green'], fontSize: 42, fontWeight: 'bold' }} >
                                    Avg. Actual
                                </Typography>
                                <Typography style={{ color: colors.neutral['green'], fontSize: 60, fontWeight: 'bold', lineHeight: "4vh" }} >
                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.AVG_PACK_SIZE == '-' ? '-' : Number(giveawayP1.AVG_PACK_SIZE).toLocaleString(undefined, { maximumFractionDigits: 2 }))) : '-'}
                                </Typography>

                                <Typography style={{ color: colors.neutral['grey'], fontSize: 42, fontWeight: 'bold', lineHeight: "20vh" }} >
                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.PACK_SIZE_STD == 'ไม่มีข้อมูล Std. Pack ในระบบ' ? 'ไม่มีข้อมูล Std. Pack ในระบบ' : 'Std. ' + Math.round(Number(giveawayP1.PACK_SIZE_STD)).toLocaleString())) : '-'}/Setting {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.SETTING_PACK_SIZE == '-' ? '-' : Math.round(Number(giveawayP1.SETTING_PACK_SIZE)).toLocaleString())) : '-'}
                                </Typography>
                            </Grid>
                        </Grid>

                        <Grid item xs={0.2} style={{ borderLeft: "2px dashed #4A4A49", height: '30vh', position: "relative", top: '0.5vw' }} >

                        </Grid>

                        <Grid item xs={7.9}>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Typography style={{ color: colors.neutral['white'], fontSize: 48, fontWeight: 'bold' }} >
                                    Giveaway
                                </Typography>
                            </Grid>
                            <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 35 }} >
                                                Forecast
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.TARGET_GIVEAWAY == '-' ? '-' : Math.round(Number(giveawayP1.TARGET_GIVEAWAY)).toLocaleString())) : '-'}%
                                            </Typography>
                                        </Grid>
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 30 }} >
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 35 }} >
                                                Best Practice
                                            </Typography>
                                            <Typography style={{ color: colors.neutral['grey'], fontSize: 48, fontWeight: 'bold', lineHeight: "4vh" }} >
                                                {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.MIN_GIVEAWAY == '-' || giveawayP1.MIN_GIVEAWAY == 'None' ? '-' : (Math.round(Number(giveawayP1.MIN_GIVEAWAY) * 100) / 100).toFixed(2))) : '-'}%
                                            </Typography>
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={6} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 60 }} >
                                            <GaugeChart
                                                value={[no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? 0 : Number(giveawayP1.ACTUAL_PERCENT_GIVEAWAY))) : 0]}
                                                setting={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.SETTING_PACK_SIZE == '-' ? 0 : Number(giveawayP1.SETTING_PACK_SIZE))) : 404}
                                                std={no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? 0 : (giveawayP1.PACK_SIZE_STD == '-' ? 0 : Number(giveawayP1.PACK_SIZE_STD))) : 400}
                                                width={450}
                                                height={100}
                                            />
                                        </Grid>
                                    </Grid>

                                    <Grid item xs={3} >
                                        <Grid item xs={12} md={12} lg={12} xl={12} textAlign="center" >
                                            <Typography style={{ color: colors.neutral['white'], fontSize: 42, fontWeight: 'bold' }} >
                                                Actual
                                            </Typography>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingRight: 20, paddingLeft: 20 }} >
                                            <ItemActual>
                                                <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                                                    <Typography style={{ color: no_data == 1 ? getColorGiveaway() : "#f5c842", fontSize: 48, fontWeight: 'bold' }} >
                                                        {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.ACTUAL_PERCENT_GIVEAWAY == '-' ? '-' : (Math.round(Number(giveawayP1.ACTUAL_PERCENT_GIVEAWAY) * 100) / 100).toFixed(2))) : '-'}
                                                    </Typography>
                                                    <Typography style={{ color: colors.neutral['white'], fontSize: 26, fontWeight: 'bold', paddingTop: 10 }} >
                                                        &nbsp;%
                                                    </Typography>
                                                </Box>
                                            </ItemActual>
                                        </Grid>

                                        <Grid item xs={12} md={12} lg={12} xl={12} style={{ paddingTop: 10, paddingRight: 20, paddingLeft: 20 }}>
                                            <ItemActual>
                                                <Typography style={{ color: no_data == 1 ? getColorGiveaway() : "#f5c842", fontSize: 48, fontWeight: 'bold' }} >
                                                    {no_data === 1 ? (giveawayP1.WARNING == 'ไม่ตรงแผนการผลิต' ? '-' : (giveawayP1.COST_SAVING_PACK == '-' ? '-' : Math.round(Number(giveawayP1.COST_SAVING_PACK)).toLocaleString())) : '-'}
                                                </Typography>
                                                <Typography style={{ color: colors.neutral['white'], fontSize: 26, fontWeight: 'bold', lineHeight: "1vh", position: "relative", top: "-0.5vw" }} textAlign="right" >
                                                    bag &nbsp;&nbsp;
                                                </Typography>
                                            </ItemActual>
                                        </Grid>

                                    </Grid>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </ItemGiveaway>
            </Grid>
        </>
    )
}

export default GiveawayDetailPage1

import { useRef, useEffect, useState } from 'react'
import { Helmet } from "react-helmet";
import { Box, Grid, Container, Tab, Tabs, Toolbar, Typography, useTheme } from "@mui/material";
import { tokens } from "../../theme";
import axios from 'axios';
import { apiUrl } from '../../constants'
import Chart from 'react-apexcharts';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";
import moment from "moment-timezone";
import OEE from "../../components/chart/OEE"
import APQ from "../../components/chart/APQ"
import BarChartAccumulativeProduction from "../../components/chart/BarChartAccumulativeProduction"
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableRow from '@mui/material/TableRow';
import LensIcon from '@mui/icons-material/Lens';
import Button from '@mui/material/Button';

const Item = styled(Paper)(({ theme }) => ({
    textAlign: 'center',
    fontFamily: 'Monospace',
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
    paddingTop: 20,
    paddingLeft: 5,
    paddingRight: 5,
}));


function getValueByKey(object, row) {
    return object[row];
}

const Home = () => {
    const exchangeToken = useExchangeToken();
    const theme = useTheme();
    const colors = tokens(theme.palette.mode);
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    const [loading, setLoading] = useState(true);
    const [lineDetailsKeys, setLineDetailsKeys] = useState();
    const [responseObject, setResponseObject] = useState();
    const [factoryPerformance, setFactoryPerformance] = useState({
        PRODUCTION_UNIT: "",
        ACTUAL_WEIGHT: "",
        CURRENT_ACTUAL_KG_PER_HOUR: "",
        CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR: "",
        CURRENT_STD_KG_PER_HOUR: "",
        CURRENT_STD_KG_PER_PERSON_PER_HOUR: "",
        EMPLOYEE: "",
        STD_EMPLOYEE: "",
        TARGET_PRODUCTION_QUANTITY: "",
        YIELD: "",
        STD_YIELD: ""
    });
    const [factoryskuOverview, setFactoryskuOverview] = useState({
        COMPLETED: "",
        RUNNING: "",
        TARGET: "",
        WAITING: "",
    });
    const [factoryOee, setFactoryOee] = useState({
        OEE: "",
        A: "",
        P: "",
        Q: "",
    });

    const today = new Date();
    const hourago = new Date(today.getTime());
    const timestr = moment(hourago).tz('Asia/Bangkok').format('YYYY-MM-DD');
    const timestrs = moment(hourago).tz('Asia/Bangkok').format('HH:mm:ss');

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);

    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);

    useEffect(() => {
        async function fetchMyAPI() {
            var get_datakorat = `${apiUrl.GET_ALL_FACTORY_DATA}${timestr}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
            await axios.get(get_datakorat, {
                headers: {
                    Authorization: `Bearer ${ac_token}`,
                },
            }
            )
                .then(response => {
                    //console.log(response)
                    if (response.status == 200) {
                        setFactoryPerformance({
                            PRODUCTION_UNIT: response.data.FACTORY_PERFORMANCE[0].PRODUCTION_UNIT,
                            ACTUAL_WEIGHT: response.data.FACTORY_PERFORMANCE[0].ACTUAL_WEIGHT,
                            CURRENT_ACTUAL_KG_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_HOUR,
                            CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR,
                            CURRENT_STD_KG_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_STD_KG_PER_HOUR,
                            CURRENT_STD_KG_PER_PERSON_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_STD_KG_PER_PERSON_PER_HOUR,
                            EMPLOYEE: response.data.FACTORY_PERFORMANCE[0].EMPLOYEE,
                            STD_EMPLOYEE: response.data.FACTORY_PERFORMANCE[0].STD_EMPLOYEE,
                            TARGET_PRODUCTION_QUANTITY: response.data.FACTORY_PERFORMANCE[0].TARGET_PRODUCTION_QUANTITY,
                            YIELD: response.data.FACTORY_PERFORMANCE[0].YIELD,
                            STD_YIELD: response.data.FACTORY_PERFORMANCE[0].STD_YIELD,
                        })
                        setFactoryskuOverview({
                            COMPLETED: response.data.FACTORY_SKU_OVERVIEW.COMPLETED,
                            RUNNING: response.data.FACTORY_SKU_OVERVIEW.RUNNING,
                            TARGET: response.data.FACTORY_SKU_OVERVIEW.TARGET,
                            WAITING: response.data.FACTORY_SKU_OVERVIEW.WAITING,
                        })
                        try {
                            setFactoryOee({
                                OEE: response.data.FACTORY_OEE[0].OEE != 'NA' ? response.data.FACTORY_OEE[0].OEE : 0,
                                A: response.data.FACTORY_OEE[0].A != 'NA' ? response.data.FACTORY_OEE[0].A : 0,
                                P: response.data.FACTORY_OEE[0].P != 'NA' ? response.data.FACTORY_OEE[0].P : 0,
                                Q: response.data.FACTORY_OEE[0].Q != 'NA' ? response.data.FACTORY_OEE[0].Q : 0,
                            })
                        }
                        catch {
                            setFactoryOee({
                                OEE: 0,
                                A: 0,
                                P: 0,
                                Q: 0,
                            })
                        }
                        let keys = ['LINE7', 'LINE8', 'LINE9', 'LINE10']

                        // Object.keys(response.data).forEach(function (key) {
                        //   keys.push(key.toString())
                        // });
                        setLineDetailsKeys(keys.filter((key) => key.startsWith("LINE") && key != 'LINE11' && key != 'LINE12'))
                        setResponseObject(response.data)
                        setLoading(false)

                    }
                })
                .catch(error => {
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
    }, []);

    useEffect(() => {
        async function fetchMyAPI() {
            var get_datakorat = `${apiUrl.GET_ALL_FACTORY_DATA}${timestr}/` // date format must be YYYY-MM-DD, eg. 2022-06-16
            await axios.get(get_datakorat, {
                headers: {
                    Authorization: `Bearer ${ac_token}`,
                },
            }
            )
                .then(response => {
                    //console.log(response)
                    if (response.status == 200) {
                        setFactoryPerformance({
                            PRODUCTION_UNIT: response.data.FACTORY_PERFORMANCE[0].PRODUCTION_UNIT,
                            ACTUAL_WEIGHT: response.data.FACTORY_PERFORMANCE[0].ACTUAL_WEIGHT,
                            CURRENT_ACTUAL_KG_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_HOUR,
                            CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR,
                            CURRENT_STD_KG_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_STD_KG_PER_HOUR,
                            CURRENT_STD_KG_PER_PERSON_PER_HOUR: response.data.FACTORY_PERFORMANCE[0].CURRENT_STD_KG_PER_PERSON_PER_HOUR,
                            EMPLOYEE: response.data.FACTORY_PERFORMANCE[0].EMPLOYEE,
                            STD_EMPLOYEE: response.data.FACTORY_PERFORMANCE[0].STD_EMPLOYEE,
                            TARGET_PRODUCTION_QUANTITY: response.data.FACTORY_PERFORMANCE[0].TARGET_PRODUCTION_QUANTITY,
                            YIELD: response.data.FACTORY_PERFORMANCE[0].YIELD,
                            STD_YIELD: response.data.FACTORY_PERFORMANCE[0].STD_YIELD,
                        })
                        setFactoryskuOverview({
                            COMPLETED: response.data.FACTORY_SKU_OVERVIEW.COMPLETED,
                            RUNNING: response.data.FACTORY_SKU_OVERVIEW.RUNNING,
                            TARGET: response.data.FACTORY_SKU_OVERVIEW.TARGET,
                            WAITING: response.data.FACTORY_SKU_OVERVIEW.WAITING,
                        })
                        try {
                            setFactoryOee({
                                OEE: response.data.FACTORY_OEE[0].OEE != 'NA' ? response.data.FACTORY_OEE[0].OEE : 0,
                                A: response.data.FACTORY_OEE[0].A != 'NA' ? response.data.FACTORY_OEE[0].A : 0,
                                P: response.data.FACTORY_OEE[0].P != 'NA' ? response.data.FACTORY_OEE[0].P : 0,
                                Q: response.data.FACTORY_OEE[0].Q != 'NA' ? response.data.FACTORY_OEE[0].Q : 0,
                            })
                        }
                        catch {
                            setFactoryOee({
                                OEE: 0,
                                A: 0,
                                P: 0,
                                Q: 0,
                            })
                        }
                        let keys = ['LINE7', 'LINE8', 'LINE9', 'LINE10']

                        // Object.keys(response.data).forEach(function (key) {
                        //   keys.push(key.toString())
                        // });
                        setLineDetailsKeys(keys.filter((key) => key.startsWith("LINE") && key != 'LINE11' && key != 'LINE12'))
                        setResponseObject(response.data)
                        setLoading(false)

                    }
                })
                .catch(error => {
                    console.log('error: ', error)

                })
        }
        fetchMyAPI()
        ////// Call API Every X Seconds in React Function Component(https://stackoverflow.com/questions/59146524/call-api-every-x-seconds-in-react-function-component)
        const interval = setInterval(() => fetchMyAPI(), 1 * 60 * 1000)
        return () => {
            clearInterval(interval);
        }

    }, []);



    return (
        <>
            <Helmet>
                <title>Home | MES</title>
            </Helmet>
            <Grid container spacing={2} style={{paddingLeft: 0}} >

                <Grid id="top-row" container sx={{ pt: 1.5 }} spacing={2}>
                    <Box sx={{ width: '120%', bgcolor: "#3cbea8", display: 'flex', alignItems: 'center', justifyContent: 'center' }} textAlign="center" >
                        <Typography style={{ color: "white", fontWeight: "bold", fontSize: "24px" }} variant="button" >
                            การผลิต
                        </Typography>
                    </Box>
                </Grid>

                <Grid id="top-row" container sx={{ pt: 0.5 }} spacing={2} style={{ paddingLeft: 0 }}>
                    {/* left column */}
                    <Grid item xs={12} md={5} xl={5} >
                        <Item>
                            <Grid container>
                                {/* actual/target weight */}
                                <Grid id="top-row" container>
                                    <Grid id="top-row" container>
                                        <Box width="100%" style={{ position: "relative", top: "-10px" }}>
                                            <Typography fontSize="22px" fontWeight="bold" align='left'>
                                                ผลผลิตสะสม
                                            </Typography>
                                        </Box>
                                        {loading === true || factoryPerformance === undefined ?
                                            <BarChartAccumulativeProduction
                                                target_quantity={0}
                                                actual_weight={0}
                                                target_now={0}
                                                unit={'TON'}
                                                height='50%'
                                            />
                                            :
                                            <BarChartAccumulativeProduction
                                                target_quantity={(Math.round(factoryPerformance.TARGET_PRODUCTION_QUANTITY * 100) / 100).toFixed(2)}
                                                actual_weight={factoryPerformance.ACTUAL_WEIGHT >= 0 ? factoryPerformance.ACTUAL_WEIGHT : 0}
                                                target_now={factoryPerformance.TARGET_NOW}
                                                unit={factoryPerformance.PRODUCTION_UNIT}
                                                height='50%'
                                            />
                                        }


                                    </Grid>
                                </Grid>
                                {/* sku overview */}
                                <Grid id="top-row" container>
                                    <Box width="100%" style={{ position: "relative" }}>
                                        <Typography fontSize="22px" fontWeight="bold" align='left'>
                                            SKUs
                                        </Typography>
                                        <table style={{ width: '100%' }} >
                                            <tbody>
                                                <tr>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 16, fontWeight: "bold", borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>Target</td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 16, fontWeight: "bold", borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>Completed</td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 16, fontWeight: "bold", borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>On&nbsp;process</td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 16, fontWeight: "bold", borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>Waiting</td>
                                                </tr>
                                                <tr
                                                    sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                >

                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 14, color: 'green', borderBottom: 'none', height: '20' }}><Button sx={{ color: '#212121' }}>{factoryskuOverview.TARGET}</Button></td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 14, borderBottom: 'none', height: '20' }}><Button variant="contained" disableElevation sx={{ color: '#e3f2fd', backgroundColor: 'green', borderColor: 'green' }}>{factoryskuOverview.COMPLETED}</Button></td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 14, borderBottom: 'none', height: '20' }}><Button variant="outlined" sx={{ color: '#212121', borderColor: 'green' }}>{factoryskuOverview.RUNNING}</Button></td>
                                                    <td align="right" style={{ width: '25%', 'text-align': 'center', fontSize: 14, borderBottom: 'none', height: '20' }}><Button sx={{ color: '#212121' }}>{factoryskuOverview.WAITING}</Button></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </Box>
                                </Grid>
                                {/* oee sections */}
                                <Grid id="top-row" container sx={{ height: "25vh" }}>
                                    {/* oee */}
                                    <Grid item xs={4.5} height='20%' sx={{ display: 'block' }}>
                                        {(loading === true || factoryOee === 'No data') ? 'No Data' :
                                            <OEE
                                                value={[Math.round(factoryOee.OEE)]}
                                                title='OEE'
                                            />
                                        }
                                    </Grid>
                                    {/* a */}
                                    <Grid item xs={2.4} height='20%' sx={{ display: 'block' }}>
                                        {(loading === true || factoryOee === 'No data') ? 'No Data' :
                                            <APQ
                                                value={[Math.round(factoryOee.A)]}
                                                title='AVA'
                                                target='92'
                                            />
                                        }
                                    </Grid>
                                    {/* p */}
                                    <Grid item xs={2.4} height='20%' sx={{ display: 'block' }}>
                                        {(loading === true || factoryOee === 'No data') ? 'No Data' :
                                            <APQ
                                                value={[Math.round(factoryOee.P)]}
                                                title='PER'
                                                target='95'
                                            />
                                        }
                                    </Grid>
                                    {/* q */}
                                    <Grid item xs={2.4} height='20%' sx={{ display: 'block' }}>
                                        {(loading === true || factoryOee === 'No data') ? 'No Data' :
                                            <APQ
                                                value={[Math.round(factoryOee.Q)]}
                                                title='QUA'
                                                target='98'
                                            />
                                        }
                                    </Grid>
                                </Grid>
                                {/* performance section */}
                                <Grid id="top-row" container>
                                    <TableContainer component={Paper}>
                                        <Table sx={{ minWidth: 120 }} aria-label="custom pagination table">
                                            <TableBody>
                                                {[
                                                    { name: "Performance", value: factoryPerformance.CURRENT_ACTUAL_KG_PER_HOUR, unit: "kg/hr", std: " (std. " + [factoryPerformance.CURRENT_STD_KG_PER_HOUR != 'NA' ? Math.round(factoryPerformance.CURRENT_STD_KG_PER_HOUR) : 'NA'] + ")" },
                                                    { name: "", value: factoryPerformance.CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR, unit: "kg/person/hr", std: " (std. " + factoryPerformance.CURRENT_STD_KG_PER_PERSON_PER_HOUR + ")" },
                                                    { name: "No. employee", value: factoryPerformance.EMPLOYEE, unit: "person", std: " (std. " + factoryPerformance.STD_EMPLOYEE + ")" },
                                                    { name: "Yield", value: factoryPerformance.YIELD, unit: "%", std: " (std. " + [factoryPerformance.STD_YIELD != 'NA' ? Math.round(factoryPerformance.STD_YIELD) : 'NA'] + "%)" }
                                                ].map((row) => (
                                                    <TableRow key={row.name}>
                                                        <TableCell style={{ width: '30%', fontSize: 16, fontWeight: 'bold' }} align="right">
                                                            {row.name}
                                                        </TableCell>
                                                        <TableCell style={{ width: '20%', fontSize: 16 }} align="right">
                                                            {row.value}
                                                        </TableCell>
                                                        <TableCell style={{ width: '20%', fontSize: 16 }} align="left">
                                                            {row.unit}
                                                        </TableCell>
                                                        <TableCell style={{ width: '30%', fontSize: 16 }} align="left">
                                                            {row.std}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                            </TableBody>
                                        </Table>
                                    </TableContainer>
                                </Grid>
                            </Grid>
                        </Item>
                    </Grid>
                    {/* right column */}
                    <Grid item xs={12} md={6.8} xl={6.8} container spacing={2}>
                        {
                            loading ? null :
                                lineDetailsKeys.map(index => {

                                    const getColorOEE = () => {
                                        let color;
                                        try {
                                            if (getValueByKey(responseObject, index).OEE.OEE >= 85) {
                                                color = "#4caf50"
                                            } else if (getValueByKey(responseObject, index).OEE.OEE >= 75 && getValueByKey(responseObject, index).OEE.OEE <= 84.99) {
                                                color = "#ffeb3b"
                                            } else if (getValueByKey(responseObject, index).OEE.OEE < 75) {
                                                color = "#e53935"
                                            }
                                            return color;
                                        }
                                        catch {
                                            console.log()
                                        }
                                    }

                                    return <Grid direction="rows" item xs={6} container sx={{ display: 'block' }}>
                                        <Item sx={{ background: "#FFFFFF" }}>
                                            <Grid item xs={12} sx={{ height: "23vh", display: 'block' }}>
                                                <Grid item xs>
                                                    {/* actual/target weight */}
                                                    <Grid id="top-row" container>
                                                        <Grid id="top-row" container>
                                                            <Box style={{ position: "relative", top: "-15px" }}>
                                                                <Button variant="contained" sx={{ bgcolor: "#e0e0e0", fontWeight: 'bold', color: "#3cbea8" }} fontSize="18px" fontWeight="bold" align='left'>
                                                                    {index}
                                                                </Button>
                                                            </Box>
                                                            <BarChartAccumulativeProduction
                                                                target_quantity={getValueByKey(responseObject, index).PERFORMANCE[0].TARGET_PRODUCTION_QUANTITY}
                                                                actual_weight={getValueByKey(responseObject, index).PERFORMANCE[0].ACTUAL_WEIGHT}
                                                                target_now={getValueByKey(responseObject, index).PERFORMANCE[0].TARGET_NOW}
                                                                unit={getValueByKey(responseObject, index).PERFORMANCE[0].PRODUCTION_UNIT}
                                                                height='40%'
                                                            />
                                                        </Grid>
                                                    </Grid>

                                                    <Grid container>
                                                        <Grid item xs={5}>
                                                            <Typography color="black" fontSize={'14px'} fontWeight="bold" align='left'>
                                                                {getValueByKey(responseObject, index).SKU_OVERVIEW.TARGET} SKUs
                                                            </Typography>
                                                            <table style={{ borderRight: "2px solid orange" }}>
                                                                <tbody style={{ 'overflow-y': 'auto', height: '50px' }}>
                                                                    {getValueByKey(responseObject, index).PRODUCTION_DETAIL.map((item, index) => {
                                                                        //change color status on condition in table map (https://stackoverflow.com/questions/63872649/change-color-on-conditions-in-table-map-in-reactjs)
                                                                        //change icon border bold (https://github.com/react-icons/react-icons/issues/232)
                                                                        //let colorStatus = item.STATUS == 'Running' ? "light" : item.STATUS == 'Completed' ? "success" : "disabled"
                                                                        let colorStroke = item.STATUS == 'Running' ? "#00d084" : item.STATUS == 'Completed' ? "#00d084" : "#FFFFFF"

                                                                        const getColor = () => {
                                                                            let color;
                                                                            try {
                                                                                if (item.STATUS == 'Completed') {
                                                                                    color = "#00d084"
                                                                                } else if (item.STATUS == 'Running') {
                                                                                    color = "#e0e0e0"
                                                                                } else if (item.STATUS == 'Not Start') {
                                                                                    color = "#FFFFFF"
                                                                                }
                                                                                return color;
                                                                            }
                                                                            catch {
                                                                                console.log()
                                                                            }
                                                                        }

                                                                        return <tr
                                                                            key={index}
                                                                            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                                                                        >
                                                                            <td align="left" style={{ width: '5%' }}>
                                                                                <LensIcon fontSize="small" stroke={colorStroke} strokeWidth="2" sx={{ color: getColor() }}>{item.STATUS}</LensIcon>
                                                                            </td>
                                                                            <td align="left" style={{ width: '95%', fontSize: 14, fontFamily: "CPF_Imm_Sook" }}>{item.MATERIAL_DESCRIPTION}</td>
                                                                        </tr>
                                                                    })}
                                                                </tbody>
                                                            </table>
                                                        </Grid>
                                                        <Grid item xs={7} container>
                                                            {/* oee sections */}
                                                            <Grid item xs={7.5}>
                                                                <Typography color="black" fontSize={'13px'} fontWeight="bold" align='left'>
                                                                    OEE
                                                                </Typography>
                                                                <Box sx={{ display: 'block' }}>
                                                                    <Chart
                                                                        options={{
                                                                            chart: {
                                                                                type: 'radialBar',
                                                                                offsetY: -20,
                                                                                offsetX: -2,
                                                                                parentHeightOffset: 0,
                                                                                toolbar: {
                                                                                    show: false
                                                                                }
                                                                            },
                                                                            plotOptions: {
                                                                                radialBar: {
                                                                                    hollow: {
                                                                                        margin: 0,
                                                                                        size: "60%",
                                                                                    },

                                                                                    dataLabels: {
                                                                                        showOn: "always",
                                                                                        name: {
                                                                                            offsetY: 15,
                                                                                            show: true,
                                                                                            color: "#888",
                                                                                            fontSize: 12,
                                                                                            fontFamily: 'CPF_Imm_Sook'
                                                                                        },
                                                                                        value: {
                                                                                            offsetY: -10,
                                                                                            color: "#111",
                                                                                            fontSize: 20,
                                                                                            fontFamily: 'CPF_Imm_Sook',
                                                                                            show: true
                                                                                        }
                                                                                    }
                                                                                }
                                                                            },
                                                                            colors: [getColorOEE()],
                                                                            labels: ['Target 85%'],
                                                                            fill: {
                                                                                type: "gradient",
                                                                                gradient: {
                                                                                    shade: "dark",
                                                                                    type: "vertical",
                                                                                    gradientToColors: [getColorOEE()],
                                                                                    stops: [0, 100]
                                                                                }
                                                                            },
                                                                        }}
                                                                        series={[getValueByKey(responseObject, index).OEE.OEE != 'NA' ? getValueByKey(responseObject, index).OEE.OEE : 0]}
                                                                        type="radialBar"
                                                                        width="100%"
                                                                        height="100%"
                                                                    />
                                                                </Box>
                                                            </Grid>
                                                            <Grid item xs={4.5}>
                                                                <table >
                                                                    <tbody>
                                                                        <tr>
                                                                            <td align="left" style={{ width: '10%', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>A: </td>
                                                                            <td align="left" style={{ width: '90%', fontSize: 14, borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>{getValueByKey(responseObject, index).OEE.A} %</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" style={{ width: '10%', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>P: </td>
                                                                            <td align="left" style={{ width: '90%', fontSize: 14, borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>{getValueByKey(responseObject, index).OEE.P} %</td>
                                                                        </tr>
                                                                        <tr>
                                                                            <td align="left" style={{ width: '10%', fontSize: 14, fontWeight: 'bold', borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>Q: </td>
                                                                            <td align="left" style={{ width: '90%', fontSize: 14, borderBottom: 'none', fontFamily: "CPF_Imm_Sook" }}>{getValueByKey(responseObject, index).OEE.Q} %</td>
                                                                        </tr>

                                                                    </tbody>
                                                                </table>
                                                            </Grid>
                                                        </Grid>
                                                    </Grid>
                                                </Grid>
                                            </Grid>
                                            <Grid item xs={12} container sx={{ display: 'block' }}>
                                                <table>
                                                    <tbody>
                                                        {[
                                                            { name: "Performance", value: getValueByKey(responseObject, index).PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_HOUR, unit: "kg/hr", std: "(std. " + [getValueByKey(responseObject, index).PERFORMANCE[0].CURRENT_STD_KG_PER_HOUR != 'NA' ? Math.round(getValueByKey(responseObject, index).PERFORMANCE[0].CURRENT_STD_KG_PER_HOUR) : 'NA'] + ")" },
                                                            { name: "", value: getValueByKey(responseObject, index).PERFORMANCE[0].CURRENT_ACTUAL_KG_PER_PERSON_PER_HOUR, unit: "kg/person/hr", std: "(std. " + getValueByKey(responseObject, index).PERFORMANCE[0].CURRENT_STD_KG_PER_PERSON_PER_HOUR + ")" },
                                                            { name: "No. employee", value: getValueByKey(responseObject, index).PERFORMANCE[0].EMPLOYEE, unit: "person", std: "(std. " + getValueByKey(responseObject, index).PERFORMANCE[0].STD_EMPLOYEE + ")" },
                                                            { name: "Yield", value: getValueByKey(responseObject, index).PERFORMANCE[0].YIELD, unit: "%", std: "(std. " + [getValueByKey(responseObject, index).PERFORMANCE[0].STD_YIELD != 'NA' ? Math.round(getValueByKey(responseObject, index).PERFORMANCE[0].STD_YIELD) : 'NA'] + "%)" }
                                                        ].map((row) => (
                                                            <tr key={row.name}>
                                                                <td style={{ width: '30%', fontSize: 14, fontWeight: 'bold,', fontFamily: "CPF_Imm_Sook" }} align="right">
                                                                    {row.name}
                                                                </td>
                                                                <td style={{ width: '20%', fontSize: 14, fontFamily: "CPF_Imm_Sook" }} align="right">
                                                                    {row.value}
                                                                </td>
                                                                <td style={{ width: '20%', fontSize: 14, fontFamily: "CPF_Imm_Sook" }} align="left">
                                                                    {row.unit}
                                                                </td>
                                                                <td style={{ width: '30%', fontSize: 12, fontFamily: "CPF_Imm_Sook" }} align="left">
                                                                    {row.std}
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </Grid>
                                        </Item>
                                    </Grid>
                                })
                        }
                    </Grid>
                </Grid>
            </Grid>

        </>
    )
}

export default Home


import React from 'react'
import { Box, Grid, Typography, useTheme } from "@mui/material";
import Chart from 'react-apexcharts';
import { ColorModeContext, tokens } from "../../theme";



////// add icon annotations (https://github.com/apexcharts/apexcharts.js/issues/1674)

function BarChartRoomStatus(props) {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

    return (
        <>
           
                <Chart
                    options={{
                        chart: {
                            type: 'bar',
                            stacked: 'true',
                            toolbar: {
                                show: false
                            },
                            zoom: {
                                enabled: false
                            }
                        },
                        plotOptions: {
                            bar: {
                                borderRadius: 0,
                                horizontal: true,
                                dataLabels: {
                                    enabled: true,
                                    enabledOnSeries: undefined,
                                    formatter: function (val, opts) {
                                        return val.toFixed(2)
                                    },
                                    textAnchor: 'start',
                                    distributed: false,
                                    offsetX: 0,
                                    offsetY: 0,
                                    total: {
                                        enabled: false,
                                        offsetX: 0,
                                        style: {
                                            fontSize: '10px',
                                            fontWeight: 900,
                                            alignItems: 'left'
                                        }
                                    }
                                }
                            }
                        },
                        colors: ['#84d8c8', '#f48c94', '#fbb2a4', '#a4aad4', '#ddd564', '#94cbcc', '#18443c'],
                        dataLabels: {
                            enabled: false,
                        },
                        legend: {
                            show: false,
                            showForSingleSeries: true,
                            customLegendItems: ['Line7', 'Line8', 'Line9', 'Line10', 'Line11', 'Line12', 'Remaining'],
                            markers: {
                                fillColors: [ '#84d8c8', '#f48c94', '#fbb2a4', '#a4aad4', '#ddd564', '#94cbcc', '#18443c']
                            }
                        },
                        tooltip: {
                            enabled: false,
                        },
                        xaxis: {
                            type: 'category',
                            categories: [], type: 'category',
                            categories: [],
                            range: props.total_instock,
                            min: 0,
                            max: props.total_instock > 96 ? props.total_instock : 96,
                            show: false,
                            labels: {
                                show: false
                            },
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false
                            },
                        },
                        yaxis: {
                            axisBorder: {
                                show: false
                            },
                            axisTicks: {
                                show: false,
                            },
                            labels: {
                                show: false,
                            },
                        },
                        grid: {
                            padding: {
                                left: -6,
                                top: -100
                            },
                            show: false,
                        },
                        annotations: {
                            xaxis: [
                                {
                                    x: props.actual,
                                    strokeDashArray: 0,
                                    borderColor: "#fff",
                                    label: {
                                        borderColor: "transparent",
                                        borderWidth: 10,
                                        borderRadius: 0,
                                        offsetX: -15,
                                        offsetY: -1,
                                        style: {
                                            color: colors.neutral['white'],
                                            background: "transparent",
                                            fontSize: 12,
                                            fontFamily: "CPF_Imm_Sook",
                                        },
                                        position: "buttom",
                                        orientation: "horizontal",
                                        text:  props.total_instock + ":" + (props.remaining < 0 ? 0 : props.remaining) 
                                    },
                                },
                            ],

                        }
                    }}

                    series={[
                        {
                            name: 'Line7',
                            data: [props.line7]
                        },
                        {
                            name: 'Line8',
                            data: [props.line8]
                        },
                        {
                            name: 'Line9',
                            data: [props.line9]
                        },
                        {
                            name: 'Line10',
                            data: [props.line10]
                        },
                        {
                            name: 'Line11',
                            data: [props.line11]
                        },
                        {
                            name: 'Line12',
                            data: [props.line12]
                        },
                        {
                            name: 'Remaining',
                            data: [props.remaining < 0 ? 0 : props.remaining]
                        }
                    ]}
                    type='bar'
                    width='100%'
                    height={props.height}
                />
  
        </>
    )
}

BarChartRoomStatus.propTypes = {}

export default BarChartRoomStatus

import { useDispatch, useSelector } from 'react-redux';
import { setState, setKit } from '../redux/authSlice';
import { useCallback, useEffect } from 'react';
import { AxpKit } from '@axons-library/portal-js-sdk';

export const useAuth = () => {
  const dispatch = useDispatch();
  const state = useSelector(state => state.auth);

  const onAuthStateChanged = useCallback(async (authState) => {
    //console.log('Auth State Changed:', authState); // ตรวจสอบการเปลี่ยนแปลงสถานะการล็อกอิน
    const activeSession = authState.sessions?.find((s) => s.sessionKey === authState.activeSessionKey);
    dispatch(setState({
      ...state,
      activeSession: activeSession || null,
      sessionKey: activeSession?.sessionKey || '',
      display: activeSession?.displayName || '',
      accessToken: activeSession?.accessToken || '',
      refreshToken: activeSession?.refreshToken || '',
      // accessTokenExpire: activeSession?.accessTokenExpire || null,
      // refreshTokenExpire: activeSession?.refreshTokenExpire || null,
      loading: false,
      isLogin: activeSession?.state === 'valid',
    }));

    if (activeSession && activeSession.state === 'valid') {
      fetchMyApps();
    } else {
      if (activeSession?.sessionKey) {
        state.kit?.clearToken(activeSession.sessionKey);
      }
      loginWithRedirect();
    }
  }, [dispatch, state.kit]);

  const init = useCallback(async () => {
    //console.log('Initializing Kit...');
    const appId = 'mes-prod';
    const baseUrl = 'https://api.axonscloud.com';
    // const appId = 'mes-uat';
    // const baseUrl = 'https://api.uat.axonscloud.com';
    try {
      const kit = AxpKit.load(appId, { baseUrl, storage: 'localStorage', defaultTenant: 'cpf' });
      kit.onActiveAuthStateChanged(onAuthStateChanged);
      await kit.init(); // ลองใส่ try-catch รอบนี้เพื่อเช็คว่าเกิดข้อผิดพลาดอะไรใน kit.init()
      dispatch(setKit(kit));
      //console.log('Kit Initialized');
    } catch (error) {
      console.log('Error initializing kit:', error);  // เพิ่มการจับข้อผิดพลาด
    }
  }, [dispatch, onAuthStateChanged]);

  useEffect(() => {
    //console.log('useAuth initialized');
    init();
  }, [init]);

  const loginWithRedirect = async () => {
    console.log('Redirecting to login...');
    let currentUrl = window.location?.href || undefined;
    state.kit?.loginWithRedirectUrl(currentUrl);
  };

  const logout = async () => {
    console.log('Logging out...');
    state.kit?.logout(state.kit.getCurrentTenantUrl());
  };

  const silentLogin = async (sessionKey) => {
    dispatch(setState({ loading: true }));
    try {
      await state.kit?.ssoSilent(sessionKey);
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setState({ loading: false }));
    }
  };

  const fetchMyApps = async () => {
    dispatch(setState({ fetching: true }));
    try {
      const resp = await state.kit?.listApplications();
      if (resp) {
        dispatch(setState({ myApps: resp.applications || [] }));
      }
    } catch (error) {
      console.log('[AuthStore] - fetchMyApps:', error);
    } finally {
      dispatch(setState({ fetching: false }));
    }
  };

  const refresh = async () => {
    dispatch(setState({ isRefreshing: true }));
    try {
      await state.kit?.refresh();
    } catch (error) {
      console.log(error);
    } finally {
      dispatch(setState({ isRefreshing: false }));
    }
  };

  // const checkTokenExpiration = () => {
  //   const currentTime = Math.floor(Date.now() / 1000);
  //   if (state.accessTokenExpire && state.accessTokenExpire <= currentTime) {
  //     console.log("Access Token expired");
  //     refresh(); // รีเฟรช access token
  //   }

  //   if (state.refreshTokenExpire && state.refreshTokenExpire <= currentTime) {
  //     console.log("Refresh Token expired");
  //     logout(); // ล็อกเอาต์เมื่อ refresh token หมดอายุ
  //   }
  // };

  return {
    ...state,
    init,
    loginWithRedirect,
    logout,
    silentLogin,
    fetchMyApps,
    refresh,
    //checkTokenExpiration,
  };
};

import { useRef, useEffect, useState } from 'react'
import * as XLSX from 'xlsx';
import { apiUrl } from '../../constants'
import axios from 'axios'
import {
  Box,
  Button,
  Card,
  CardContent,
  TextField,
  InputAdornment,
  SvgIcon,
  Link,
  Grid
} from "@material-ui/core";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import AddIcon from '@mui/icons-material/Add';
import UploadFileIcon from "@mui/icons-material/UploadFile";
import ExportExcel from "./ExportExcel";
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";

function StandardSettingToolbar(props) {
  const exchangeToken = useExchangeToken();
  const [postData, setPostData] = useState(null);
  const [getData, setGetData] = useState([])
  const [isTokenExchanged, setIsTokenExchanged] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useCheckToken();
  const ac_token = useSelector(state => state.auth.access_token);


  useEffect(() => {
    const exchange = async () => {
      if (!isTokenExchanged) {
        try {
          await exchangeToken(); // แลกเปลี่ยนโทเค็น
          setIsTokenExchanged(true);
        } catch (err) {
          console.error("Error exchanging token:", err);
          setError("Failed to exchange token");
        }
      }
    };

    exchange();
  }, [isTokenExchanged, exchangeToken]);


  const handleFileUpload = (e) => {
    const file = e.target.files[0];
    const reader = new FileReader();

    reader.onload = (event) => {
      const workbook = XLSX.read(event.target.result, { type: 'binary' });
      const sheetName = workbook.SheetNames[0];
      const sheet = workbook.Sheets[sheetName];
      const sheetData = XLSX.utils.sheet_to_json(sheet);

      setPostData(sheetData);

    };

    reader.readAsBinaryString(file);
  };

  const newArr = postData === null ? [] : postData.map(({ __rowNum__, ...rest }) => {
    return rest;
  });

  // console.log(newArr[0])




  useEffect(() => {
    async function fetchMyAPI() {
      console.log(newArr[0])
      await axios.post(`${apiUrl.POST_MASTER_STD}`, newArr[0], {
        headers: {
          Authorization: `Bearer ${ac_token}`,
          // "X-xss-protection": "1; mode=block;",
          // "X-frame-options": "SAMEORIGIN",
          // "X-content-type-options": "nosniff",
          // "Cache-Control": "no-cache, private",
          // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
          // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
        }
      })
        .then(response => {
          // console.log(response)
          if (response.status == 201) {
            console.log(response.data)
            window.location.reload(true);
          }
          else {

          }
        })
        .catch(error => {
          console.log('error: ', error);
        })
    }

    if (postData !== null) {

      fetchMyAPI()
    }

  }, [newArr[0]]);



  useEffect(() => {
    const fetchMyAPI = async () => {
      if (!ac_token) return; // ถ้าไม่มีโทเค็น ให้รอ
      setLoading(true);
      try {
        const response = await axios.get(`${apiUrl.GET_MASTER_STD}get/${sessionStorage.getItem('master_std_plant')}/${sessionStorage.getItem('master_std_factory')}/`, {
          headers: {
            Authorization: `Bearer ${ac_token}`,
            // "X-xss-protection": "1; mode=block;",
            // "X-frame-options": "SAMEORIGIN",
            // "X-content-type-options": "nosniff",
            // "Cache-Control": "no-cache, private",
            // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
            // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
          },
        });

        if (response.status === 200) {
          setGetData(response.data)
        }
      } catch (err) {
        setError(err.message);
        console.error("Error fetching BU data:", err);
      } finally {
        setLoading(false);
      }
    };

    if (isTokenExchanged && ac_token) {
      fetchMyAPI(); // เรียก API เมื่อโทเค็นพร้อม
    }
  }, [isTokenExchanged, ac_token]);


  return (
    <Box {...props}>
      <Grid item xs={12} >
        <Grid container rowSpacing={0} columnSpacing={{ xs: 0, sm: 0, md: 0 }}>
          <Grid item xs={0.8}>
            <Button
              component={RouterLink}
              to="/new-master-std"
              variant="contained"
              style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#009688' }}
            >
              <AddIcon style={{ color: '#ffffff' }} />
              New
            </Button>
          </Grid>
          <Grid item xs={0.8}>
            <Button
              component="label"
              startIcon={<UploadFileIcon style={{ color: '#ffffff' }} />}
              style={{ borderRadius: 5, fontSize: 16, fontWeight: "bold", background: '#9c9797', color: '#ffffff' }}
            >
              <input type="file" hidden onChange={handleFileUpload} />
              Import
            </Button>
          </Grid>
          <Grid item xs={0.8}>
            <ExportExcel excelData={getData} fileName={"Master STD"} />
          </Grid>
        </Grid>
      </Grid>
    </Box>
  );
}

StandardSettingToolbar.propTypes = {};

export default StandardSettingToolbar;

import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Box, Grid, Card, Container, Typography, Button } from "@mui/material";
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { styled } from '@mui/material/styles';
import Home from "../mes_korat_on_web/Home";
import Monitor from "../mes_korat_on_web/Monitor"
import Production from "../mes_korat_on_web/Production";
import ReportYerterdayLine7 from "./ReportYesterdayLine7";
import axios from 'axios';
import { apiUrl } from '../../constants'
import Circular from '../../components/model/Circular'
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';






function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && (
                <Box sx={{ p: 3 }}>
                    <Typography>{children}</Typography>
                </Box>
            )}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}

export default function OverviewTab() {
    const [value, setValue] = useState(0);
    const [updatePO, setUpdatePO] = useState();
    const [notifyUpdatePOSuccessful, setNotifyUpdatePOSuccessful] = useState(false);
    const [notifyUpdatePOFailed, setNotifyUpdatePOFailed] = useState(false);
    const [done, setDone] = useState(false);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };

    const StyledTab = styled(Tab)({
        "&.Mui-selected": {
            color: "red"
        }
    });

    // const handleUpdatePO = async () => {
    //     await axios.get(`${apiUrl.UPDATE_PO}`, {
    //         "headers": {
    //             "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\F--Jme7EAQ){-A2x)",
    //         }
    //     })
    //         .then(response => {
    //             console.log(response.status)
    //             if (response.status == 200) {
    //                 console.log("update PO");
    //                 setUpdatePO(1);
    //                 setNotifyUpdatePOSuccessful(true);
    //                 setDone(false);
    //             }
    //             else {
    //                 setUpdatePO(2)
    //             }
    //         })
    //         .catch(error => {
    //             if (error.request.status === 504) {
    //                 setUpdatePO(1);
    //                 setNotifyUpdatePOSuccessful(true);
    //                 setDone(false);
    //             } else {
    //                 setDone(false);
    //                 console.log('update po error: ', error);
    //             }
    //         })
    // }

    const handleUpdatePO = async () => {
        try {
            const response = await axios.get(`${apiUrl.UPDATE_PO}`, {
                "headers": {
                    "authorizationToken": "lFao}A!iCSg3v'sT{=*p*irT2f@#.X(9.1B:M9R'z*Qy=y\\F--Jme7EAQ){-A2x)",
                },
                timeout: 5000 // ตั้ง timeout
            });
            if (response.status === 200) {
                console.log("Update PO successful");
                setUpdatePO(1);
                setNotifyUpdatePOSuccessful(true);
            } else {
                console.error("Unexpected response:", response);
                setUpdatePO(2);
                setNotifyUpdatePOFailed(true);
            }
        } catch (error) {
            console.error("Error while updating PO:", error);
            if (error.code === 'ECONNABORTED' || error.response?.status === 504) {
                setUpdatePO(1);
                setNotifyUpdatePOSuccessful(true);
            } else {
                setUpdatePO(2);
                setNotifyUpdatePOFailed(true);
            }
        } finally {
            setDone(false);
        }
    };




    return (

        <div className="App">
            <Helmet>
                <title>Overview | MES</title>
            </Helmet>
            <Grid
                container
                style={{ paddingTop: 0, backgroundColor: '#e0f2f1' }}
            >
                <Grid item lg={12} md={12} sm={12} xl={12} xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                    <Box sx={{ width: '100%' }}>
                        <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                            <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" >
                                {/* <Tab label="Home" {...a11yProps(0)} /> */}
                                <Tab label="Monitor" {...a11yProps(0)} />
                                <Tab label="Production" {...a11yProps(1)} />
                                {/* <Tab label="Report Line7" {...a11yProps(2)} /> */}
                                {done ? <Circular /> : <Button style={{color: '#4a4a49'}} onClick={handleUpdatePO} >

                                    Update PO
                                </Button>
                                }
                            </Tabs>
                        </Box>
                        {/* <CustomTabPanel value={value} index={0}>
                            <Home />
                        </CustomTabPanel> */}

                        <CustomTabPanel value={value} index={0}>
                            <Monitor />
                        </CustomTabPanel>

                        <CustomTabPanel value={value} index={1}>
                            <Production />
                        </CustomTabPanel>

                        {/* <CustomTabPanel value={value} index={3}>
                            <ReportYerterdayLine7 />
                        </CustomTabPanel> */}
                    </Box>

                    {updatePO == 1 ?
                        <div style={{ marginTop: 20 }}>
                            <Dialog
                                open={notifyUpdatePOSuccessful}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                        Successfully update PO
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ fontFamily: 'Monospace', backgroundColor: '#009688' }} variant="contained" onClick={() => { setNotifyUpdatePOSuccessful(false); }}>OK</Button>
                                </DialogActions>
                            </Dialog>

                        </div>
                        : console.log()
                    }
                    {updatePO == 2 ?
                        <div style={{ marginTop: 20 }}>
                            <Dialog
                                open={notifyUpdatePOFailed}
                                aria-labelledby="alert-dialog-title"
                                aria-describedby="alert-dialog-description"
                            >
                                <DialogContent>
                                    <DialogContentText id="alert-dialog-description" minWidth='200px' align='center'>
                                        Failed to update PO, Plead try again
                                    </DialogContentText>
                                </DialogContent>
                                <DialogActions>
                                    <Button style={{ fontFamily: 'Monospace', backgroundColor: '#009688' }} aligh='Center' variant="contained" onClick={() => { setNotifyUpdatePOFailed(false); }}>OK</Button>
                                </DialogActions>
                            </Dialog>
                        </div>
                        : console.log()
                    }
                </Grid>
            </Grid>
        </div>
    );
}






import PropTypes from 'prop-types';
import PerfectScrollbar from 'react-perfect-scrollbar';
import {
    Avatar,
    Box,
    Card,
    Checkbox,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
    Typography,
    Button
} from '@material-ui/core';
import { apiUrl } from "../../constants";
import React, { useEffect, useState } from 'react';
import axios from 'axios';
import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import { Link as RouterLink, useNavigate } from 'react-router-dom';
import EditNoteIcon from '@mui/icons-material/EditNote';
import { useSelector } from 'react-redux';
import useCheckToken from '../../hooks/useCheckToken';
import useExchangeToken from "../../hooks/useExchangeToken";



const MySwal = withReactContent(Swal);

function MasterPlantList({ customers, ...rest }) {
    const exchangeToken = useExchangeToken();
    const navigate = useNavigate();
    const [selectedCustomerIds, setSelectedCustomerIds] = useState([]);
    const [limit, setLimit] = useState(10);
    const [page, setPage] = useState(0);
    const [deleteButton, setDeleteButton] = useState(false);
    const [deleteNumber, setdeleteNumber] = useState(0);
    const [idUser, setId] = useState();
    const [status, setStatus] = useState('');
    const [select, setSelect] = useState();
    const [isTokenExchanged, setIsTokenExchanged] = useState(false);
    const [error, setError] = useState(null);

    useCheckToken();
    const ac_token = useSelector(state => state.auth.access_token);


    useEffect(() => {
        const exchange = async () => {
            if (!isTokenExchanged) {
                try {
                    await exchangeToken(); // แลกเปลี่ยนโทเค็น
                    setIsTokenExchanged(true);
                } catch (err) {
                    console.error("Error exchanging token:", err);
                    setError("Failed to exchange token");
                }
            }
        };

        exchange();
    }, [isTokenExchanged, exchangeToken]);

   
    // will be triggered when user click select all
    const handleSelectAll = (event) => {
        let newSelectedCustomerIds;

        if (event.target.checked) {
            newSelectedCustomerIds = customers.map((customer) => customer.id);
            // console.log(newSelectedCustomerIds);
            setDeleteButton(true);
            setdeleteNumber(newSelectedCustomerIds.length);
            setId(newSelectedCustomerIds);
        } else {
            newSelectedCustomerIds = [];
            setDeleteButton(false);
            setId(newSelectedCustomerIds);
        }

        setSelectedCustomerIds(newSelectedCustomerIds);
    };

    // will be triggered when user click select one user
    const handleSelectOne = (event, id) => {
        const selectedIndex = selectedCustomerIds.indexOf(id);
        let newSelectedCustomerIds = [];
        if (selectedIndex === -1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedCustomerIds,
                id
            );
            // console.log(id);
            setDeleteButton(true);
            setdeleteNumber(newSelectedCustomerIds.length);
            setId(newSelectedCustomerIds);

            // console.log(newSelectedCustomerIds)
        } else if (selectedIndex === 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedCustomerIds.slice(1)
            );
            // console.log(newSelectedCustomerIds)
            if (newSelectedCustomerIds.length == 0) {
                setDeleteButton(false);
            } else {
                setDeleteButton(true);
                setId();
                setdeleteNumber(newSelectedCustomerIds.length);
            }

        } else if (selectedIndex === selectedCustomerIds.length - 1) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedCustomerIds.slice(0, -1)
            );
            setDeleteButton(true);
            setId(newSelectedCustomerIds);
            // console.log(newSelectedCustomerIds)
            setdeleteNumber(newSelectedCustomerIds.length);
        } else if (selectedIndex > 0) {
            newSelectedCustomerIds = newSelectedCustomerIds.concat(
                selectedCustomerIds.slice(0, selectedIndex),
                selectedCustomerIds.slice(selectedIndex + 1)
            );
            setDeleteButton(true);
            setId();
            setdeleteNumber(newSelectedCustomerIds.length);
            // console.log(newSelectedCustomerIds)
        }

        setSelectedCustomerIds(newSelectedCustomerIds);
    };

    // set row in page
    const handleLimitChange = (event) => {
        setLimit(parseInt(event.target.value, 10));
        setPage(0);
    };

    // set page
    const handlePageChange = (event, newPage) => {
        // console.log(event)
        setPage(newPage);
    };

    // will be triggered when user click delete button and delete that user by id
    const handleDelete = async () => {
        // console.log('delete')
        // console.log(idUser)
        // console.log(selectedCustomerIds)
        MySwal.fire({
            title: 'Are you sure to delete?',
            text: "You won't be able to revert this!",
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!'
        }).then(async (result) => {
            if (result.value) {
                // console.log(result.value)
                // console.log('go to delete');
                try {
                    for (let i = 0; i < selectedCustomerIds.length; i++) {

                        const chack_on_delete = select === undefined ? '' : select.check_on
                        var result = await axios.delete(`${apiUrl.EDIT_MASTER_PLANT}delete/${chack_on_delete}/`, {
                            headers: {
                                Authorization: `Bearer ${ac_token}`,
                                // "X-xss-protection": "1; mode=block;",
                                // "X-frame-options": "SAMEORIGIN",
                                // "X-content-type-options": "nosniff",
                                // "Cache-Control": "no-cache, private",
                                // "Strict-Transport-Security": "max-age=31536000; includeSubDomains; preload",
                                // "Content-Security-Policy": "upgrade-insecure-requests; frame-ancestors 'self'"
                            }
                        })
                    }
                    // console.log(result)
                    // var result = { status: 200 };
                    if (result.status == 200) {
                        MySwal.fire({
                            title: 'Done!',
                            text: 'Successfully deleted master plant.',
                            icon: 'success',
                            confirmButtonText: 'Ok,Got it!'
                        }).then(() => {
                            window.location.reload(true);
                        });
                    } else {
                        MySwal.fire({
                            title: 'Error!',
                            text: 'Can not delete this master plant.',
                            icon: 'error',
                            confirmButtonText: 'Ok,Got it!'
                        });
                    }
                } catch (error) {
                    console.log('error');
                    console.log(error);
                }
            } else {
                // console.log('cancle delete');
            }
        });
    };



    return (
        <Card {...rest}>
            <Box
                sx={{ width: '100%', backgroundColor: 'pink', p: 1 }}
                style={{ display: deleteButton ? 'block' : 'none' }}
            >
                <Button style={{ color: 'white', backgroundColor: 'red' }} onClick={handleDelete}>
                    Delete
                </Button>
                <Button style={{ color: 'red' }}>
                    &nbsp;&nbsp;{deleteNumber} rows Selected
                </Button>
            </Box>

            <PerfectScrollbar>
                <Box sx={{ minWidth: 1200 }}>
                    <Table aria-labelledby="tableTitle">
                        <TableHead style={{ backgroudColor: 'black' }}>
                            <TableRow>
                                <TableCell padding="checkbox">
                                    <Checkbox
                                        checked={selectedCustomerIds.length === customers.length}
                                        color="primary"
                                        indeterminate={
                                            selectedCustomerIds.length > 0 &&
                                            selectedCustomerIds.length < customers.length
                                        }
                                        onChange={handleSelectAll}
                                    />
                                </TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Bu</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Plant</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Factory</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Line</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Module</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Status</TableCell>
                                <TableCell style={{ fontSize: 20, fontWeight: 'bold' }}>Action</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {customers.length === 0 ? [] : customers
                                .slice(page * limit, page * limit + limit)
                                .map((customer) => (
                                    <TableRow
                                        hover
                                        key={customer.id}
                                        selected={selectedCustomerIds.indexOf(customer.id) !== -1}
                                    >
                                        <TableCell padding="checkbox">
                                            <Checkbox
                                                checked={
                                                    selectedCustomerIds.indexOf(customer.id) !== -1
                                                }
                                                onChange={(event) =>
                                                    handleSelectOne(event, customer.id)
                                                }
                                                value="true"
                                                onClick={() => { setSelect({ check_on: customer.check_on }) }}
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <Box
                                                sx={{
                                                    alignItems: 'center',
                                                    display: 'flex'
                                                }}
                                            >
                                                <Typography color="textPrimary" style={{ fontSize: 18 }}>
                                                    {customer.bu}
                                                </Typography>
                                            </Box>
                                        </TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{customer.plant_id}</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{customer.factory}</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{customer.line}</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{customer.module}</TableCell>
                                        <TableCell style={{ fontSize: 18 }}>{customer.status}</TableCell>
                                        <TableCell>
                                            <EditNoteIcon
                                                onClick={() => navigate(`/edit-master-plant?id=${customer.id}`, { replace: true })}
                                                style={{ fontSize: 22 }}
                                            />
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </Box>
            </PerfectScrollbar>
            <TablePagination
                component="div"
                count={customers.length}
                onPageChange={handlePageChange}
                onRowsPerPageChange={handleLimitChange}
                page={page}
                rowsPerPage={limit}
                rowsPerPageOptions={[5, 10, 25, { label: 'All', value: -1 }]}
            />
        </Card>
    );
}

MasterPlantList.propTypes = {
    customers: PropTypes.array.isRequired
};

export default MasterPlantList;
